import React from "react";

const catButton = ({
  cat,
  onCategory,
  active,
  setActive,
  setActiveCategory,
}) => {
  const getRandomColor = (id) => {
    let colors = [
      "bg-red-200",
      "bg-yell",
      "bg-green-200",
      "bg-blue-200",
      "bg-purp",
      "bg-brown",
      "bg-pink-200",
      "bg-lime",
      "bg-cyan",
    ];
    return colors[id % colors.length];
  };
  const getRandomBorder = (id) => {
    let borders = [
      "border-red-400",
      "border-yellDark",
      "border-green-400",
      "border-blue-400",
      "border-purpDark",
      "border-brownDark",
      "border-pink-400",
      "border-limeDark",
      "border-cyanDark",
    ];
    return borders[id % borders.length];
  };

  return (
    <button
      onClick={() => {
        onCategory(cat.id);
        setActive(cat.id);
        setActiveCategory(cat.title);
      }}
      className={`${
        active === cat.id
          ? "border-t-4 scale-90 border-2"
          : "border-b-4 scale-100 shadow-lg"
      } flex-none w-20 h-20 mt-2 p-1 font-semibold leading-none text-sm ${getRandomColor(
        cat.id
      )}  ${getRandomBorder(
        cat.id
      )} rounded-lg transfrom transition-all duration-150s ease-in-out `}
    >
      {cat.title}
    </button>
  );
};

export default catButton;
