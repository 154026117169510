import React from "react";
import Moment from "react-moment";

const OnlineOrderCard = ({ order, active, showDetails }) => {
  return (
    <div>
      <div
        key={order.id}
        onClick={() => {
          showDetails(order.id);
        }}
        className={`w-full p-2  ${
          active === order.id
            ? `bg-yellow-200 border-yellow-400`
            : `bg-green-50 border-green-300`
        } border border-b-4 rounded-md shadow h-28 w-28 relative  `}
      >
        <div className="flex items-center justify-between gap-2">
          <h4 className="text-xl font-semibold text-left">{order.id}</h4>
          {order.status === 0 ? (
            <span className="relative flex w-2 h-2">
              <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
              <span className="relative inline-flex w-2 h-2 bg-red-500 rounded-full"></span>
            </span>
          ) : null}

          {order.status === 1 ? (
            <span className="relative flex w-2 h-2">
              <span className="absolute inline-flex w-full h-full bg-blue-400 rounded-full opacity-75 animate-ping"></span>
              <span className="relative inline-flex w-2 h-2 bg-blue-500 rounded-full"></span>
            </span>
          ) : null}

          {order.status === 2 ? (
            <span className="relative flex w-2 h-2">
              <span className="absolute inline-flex w-full h-full bg-yellow-400 rounded-full opacity-75 animate-ping"></span>
              <span className="relative inline-flex w-2 h-2 bg-yellow-500 rounded-full"></span>
            </span>
          ) : null}
        </div>
        <p className="text-sm text-left">
          <Moment format="DD,MMM-YY">{order.created_at}</Moment>
        </p>
        <p className="text-sm font-semibold text-left ">{order.orderType}</p>
        <h1
          className={`absolute text-lg font-bold ${
            active === order.id ? `text-green-500` : `text-blue-500`
          } bottom-1 right-1`}
        >
          £{order.total}
        </h1>

        {/* <div className="grid grid-cols-2">
          <div>
            <div className="flex items-center justify-start gap-2">
              <h4 className="text-base font-semibold text-left">
                Online [#{order.id}]
              </h4>
              {order.status === 0 ? (
                <span className="relative flex w-2 h-2">
                  <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-2 h-2 bg-red-500 rounded-full"></span>
                </span>
              ) : null}

              {order.status === 1 ? (
                <span className="relative flex w-2 h-2">
                  <span className="absolute inline-flex w-full h-full bg-blue-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-2 h-2 bg-blue-500 rounded-full"></span>
                </span>
              ) : null}

              {order.status === 2 ? (
                <span className="relative flex w-2 h-2">
                  <span className="absolute inline-flex w-full h-full bg-yellow-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-2 h-2 bg-yellow-500 rounded-full"></span>
                </span>
              ) : null}
            </div>

            <p className="text-left">
              <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
            </p>
            <p className="text-left">
              Payment:{" "}
              <span className="font-semibold text-green-500">
                {order.payment}
              </span>
            </p>
          </div>
          <div>
            <p className="text-base text-left">
              Sub Total:
              <span className="ml-1 font-semibold text-blue-600">
                £{parseFloat(order.subTotal).toFixed(2)}
              </span>
            </p>

            <p className="text-xl font-semibold text-left">
              Total:
              <span className="ml-1 text-green-500">
                £{parseFloat(order.total).toFixed(2)}
              </span>
            </p>
          </div>
        </div> */}
        {/* {order.payment === "Paypal" ? (
          <p className="text-left">
            TxID:{" "}
            <span className="font-semibold text-blue-500">
              {order.paypal_txd}
            </span>
          </p>
        ) : null} */}
      </div>
    </div>
  );
};

export default OnlineOrderCard;
