/* eslint-disable eqeqeq */
import React from "react";

import OrderList from "../images/orderList.png";
import Report from "../images/report.png";
import Users from "../images/users.png";
import OnlineOrder from "../images/onlineOrder.png";
import miyabi from "../images/logo.png";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Star from "./Star";
import Refund from "./Refund";
import { useLocation } from "react-router-dom";

const Navs = ({
  cart,
  setSmokeCart,
  activeTable,
  apiCustomer,
  isSaveClient,
  onlineOrderCount,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  const [removeClient, setRemoveClient] = useState(-1);

  const location = useLocation();

  useEffect(() => {
    const userData = cart.filter((item) => item.id === activeTable);
    if (userData.length > 0) {
      setRemoveClient(userData[0].clientID);
    }
  }, [activeTable, cart]);

  useEffect(() => {
    const fetchSmokeusers = async () => {
      let url = apiCustomer;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const getSmokeUsers = async () => {
      const smokeData = await fetchSmokeusers();
      setUserData(smokeData);
      setSearchData(smokeData);
    };

    getSmokeUsers();
  }, [apiCustomer, isSaveClient]);

  const selectUser = (id) => {
    const data = userData.filter((user) => user.id === id);

    const UpdatedCart = cart.map((item) =>
      item.id === activeTable
        ? {
            ...item,
            clientID: data[0].id,
            clientFname: data[0].fname,
            clientLname: data[0].lname,
            clientEmail: data[0].email,
            clientPhone: data[0].phone,
            clientHouse: data[0].house,
            clientStreet: data[0].street,
            clientCity: data[0].city,
            clientPostcode: data[0].postcode,
          }
        : item
    );
    setSmokeCart(UpdatedCart);
    setRemoveClient(data[0].id);

    // setSmokeClientID(id);
    // setClientFname(data[0].fname);
    // setClientLname(data[0].lname);
    // setClientEmail(data[0].email);
    // setClientPhone(data[0].phone);
    // setClientHouse(data[0].house);
    // setClientStreet(data[0].street);
    // setClientCity(data[0].street);
    // setClientPostcode(data[0].postcode);

    setOpenSearch(false);
  };

  const clearClient = () => {
    const UpdatedCart = cart.map((item) =>
      item.id === activeTable
        ? {
            ...item,
            clientID: -1,
            clientFname: "",
            clientLname: "",
            clientEmail: "",
            clientPhone: "",
            clientHouse: "",
            clientStreet: "",
            clientCity: "",
            clientPostcode: "",
          }
        : item
    );
    setSmokeCart(UpdatedCart);
    setRemoveClient(-1);
  };

  const searchUser = (q) => {
    const data = userData.filter((user) => {
      if (
        user.fname.toLowerCase().includes(q.toLowerCase()) ||
        user.postcode.includes(q)
      ) {
        return user;
      } else {
        return null;
      }
    });
    setSearchData(data);
  };

  // useEffect(() => {
  //   const fetchSmokeusers = async () => {
  //     let url = apiCustomer;
  //     const res = await fetch(url);
  //     const data = await res.json();
  //     return data;
  //   };

  //   const getSmokeUsers = async () => {
  //     const smokeData = await fetchSmokeusers();
  //     setUserData(smokeData);
  //     setSearchData(smokeData);
  //   };

  //   getSmokeUsers();
  // }, [apiCustomer]);

  return (
    <div>
      {location.pathname !== "/" ? (
        <div className="flex flex-wrap items-center justify-between w-full space-x-2 rounded-md">
          <div className="flex items-center gap-2">
            <NavLink
              className="border-2 border-white rounded-2xl"
              to="/"
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
            >
              <button className="p-4 font-bold text-white bg-red-500 border-b-4 border-red-800 rounded-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </button>
            </NavLink>

            <NavLink
              className="border-2 border-white rounded-2xl"
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
              to={{ pathname: `/epos/miyabi` }}
            >
              <button className="p-2 bg-gray-200 border-b-4 border-red-500 rounded-2xl">
                <img src={miyabi} alt="miyabi" className="w-10 h-10 mx-auto" />
              </button>
            </NavLink>

            <NavLink
              className="border-2 border-white rounded-2xl"
              to={{
                pathname: "/epos/orderlist",
              }}
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
            >
              <button className="p-2 bg-blue-300 border-b-4 border-blue-600 rounded-2xl">
                <img
                  src={OrderList}
                  alt="order list"
                  className="w-10 h-10 mx-auto"
                />
              </button>
            </NavLink>

            <NavLink
              className="border-2 border-white rounded-2xl"
              to={{
                pathname: "/epos/onlineOrderlist",
              }}
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
            >
              <button className="relative p-2 bg-green-300 border-b-4 border-green-600 rounded-2xl">
                {onlineOrderCount.countAccept > 0 ? (
                  <span className="absolute top-0 right-0 flex w-6 h-6">
                    <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
                    <span className="relative inline-flex px-2 py-0 mx-auto font-semibold text-center text-white bg-red-500 rounded-full">
                      {onlineOrderCount.countAccept}
                    </span>
                  </span>
                ) : (
                  <>
                    {onlineOrderCount.countProcess > 0 ? (
                      <span className="absolute top-0 right-0 flex w-6 h-6">
                        <span className="absolute inline-flex w-full h-full bg-blue-400 rounded-full opacity-75 animate-ping"></span>
                        <span className="relative inline-flex px-2 py-0 mx-auto font-semibold text-center text-white bg-blue-500 rounded-full">
                          {onlineOrderCount.countProcess}
                        </span>
                      </span>
                    ) : (
                      <>
                        {onlineOrderCount.countReady > 0 ? (
                          <span className="absolute top-0 right-0 flex w-6 h-6">
                            <span className="absolute inline-flex w-full h-full bg-yellow-400 rounded-full opacity-75 animate-ping"></span>
                            <span className="relative inline-flex px-2 py-0 mx-auto font-semibold text-center text-white bg-yellow-500 rounded-full">
                              {onlineOrderCount.countReady}
                            </span>
                          </span>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                <img
                  src={OnlineOrder}
                  alt="order list"
                  className="w-10 h-10 mx-auto"
                />
              </button>
            </NavLink>

            <NavLink
              className="border-2 border-white rounded-2xl"
              to="/epos/salesReport"
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
            >
              <button className="p-2 bg-yellow-300 border-b-4 border-yellow-600 rounded-2xl">
                <img
                  src={Report}
                  alt="sales report"
                  className="w-10 h-10 mx-auto"
                />
              </button>
            </NavLink>

            <NavLink
              className="border-2 border-white rounded-2xl"
              to={{
                pathname: "/epos/customerList",
              }}
              exact={true}
              activeClassName="border-blue-400 bg-blue-400"
            >
              <button className="p-2 bg-pink-300 border-b-4 border-pink-600 rounded-2xl">
                <img
                  src={Users}
                  alt="customers"
                  className="w-10 h-10 mx-auto"
                />
              </button>
            </NavLink>

            <button
              onClick={() => setOpenSearch(true)}
              className="p-2 text-white bg-blue-400 border-b-4 border-blue-700 rounded-2xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>

            {removeClient > 0 ? (
              <button
                onClick={clearClient}
                className="text-white bg-red-400 border-b-4 border-red-700 rounded-2xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-10 h-10 p-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
                </svg>
              </button>
            ) : null}
            {location.pathname == "/epos/salesReport" ||
            location.pathname == "/epos/salesReport/online" ? (
              <>
                <NavLink
                  className="border-2 border-white rounded-lg"
                  to={{
                    pathname: "/epos/salesReport",
                  }}
                  exact={true}
                  activeClassName="border-blue-400 bg-blue-400"
                >
                  <button className="p-2 font-bold text-white bg-blue-500 border-b-4 border-blue-700 rounded-lg">
                    Shop
                  </button>
                </NavLink>

                <NavLink
                  className="border-2 border-white rounded-lg"
                  to={{
                    pathname: "/epos/salesReport/online",
                  }}
                  exact={true}
                  activeClassName="border-blue-400 bg-blue-400"
                >
                  <button className="p-2 font-bold text-white bg-green-500 border-b-4 border-green-700 rounded-lg">
                    Online
                  </button>
                </NavLink>
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {openSearch ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="m-5 search-wrapper">
            <div className="flex items-center justify-between">
              <input
                type="text"
                onChange={(e) => searchUser(e.target.value)}
                placeholder="Search Customer"
                className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg w-96 focus:border-yellow-400"
              />

              <button
                onClick={() => setOpenSearch(false)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {searchData.length > 0 ? (
              <div className="w-full p-4 bg-white rounded-lg shadow-xl searchContainer">
                {searchData.map((user) => (
                  <div
                    key={user.id}
                    className="w-full p-3 my-2 bg-gray-100 rounded-md"
                  >
                    <div className="flex justify-between items-top">
                      <div>
                        <Star orderCount={user.orderCount} />
                        <p className="text-sm font-semibold text-left">
                          Orders: {user.orderCount}
                        </p>
                        <h4 className="text-xl font-semibold text-left">
                          {user.fname} {user.lname}
                        </h4>
                        <div className="d-flex">
                          <p className="text-base text-left">{user.phone}</p>
                        </div>
                        <p className="text-sm text-left">{user.email}</p>
                      </div>
                      <div>
                        <Refund refundCount={user.refundCount} />
                        <p className="text-sm font-semibold text-right">
                          Refund:{user.refundCount}
                        </p>
                        <p className="text-sm text-right">
                          {user.house} {user.street}
                        </p>

                        <p className="text-sm text-right">
                          {user.city}
                          <span className="font-medium"> {user.postcode}</span>
                        </p>

                        <button
                          onClick={() => selectUser(user.id)}
                          className="float-right px-4 py-2 font-bold text-white bg-blue-400 border-b-4 border-blue-700 rounded-md"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full p-4 bg-white rounded-lg shadow-xl searchContainer">
                <p>No User Found</p>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navs;
