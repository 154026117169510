import React from "react";
import FoodButton from "./ui/FoodButton";
const food = ({ foods, onAdd, addNest, activeCategory, activeTable, toppings, toppingOptions, onToppingAdd, addNestTopping}) => {
  return (
    <div className="p-1 mx-auto rounded-lg">
      <h1 className="mb-1 text-base font-bold text-left">
        Food Items - {activeCategory}
      </h1>
      <div className=" food-wrapper">
        <div className="grid grid-cols-4 gap-1">
          {foods.map((food) => (
            <FoodButton
              onAdd={onAdd}
              onToppingAdd={onToppingAdd}
              food={food}
              addNest={addNest}
              addNestTopping={addNestTopping}
              key={food.id}
              activeTable={activeTable}
              toppings={toppings}
              toppingOptions={toppingOptions}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default food;
