import React from "react";
import Moment from "react-moment";

const OnlineOrderPrint = ({
  smokeInfo,
  selOrder,
  cartID,
  foodName,
  qty,
  foodPrice,
  condId,
  condName,
  condValue,
}) => {
  return (
    <div>
      <div className="px-0">
        <h1 className="text-4xl font-bold text-center">{smokeInfo["name"]}</h1>
        <p className="text-lg text-center">{smokeInfo["address1"]}</p>
        <p className="text-lg text-center">{smokeInfo["address2"]}</p>
        <p className="text-lg text-center">Tel: {smokeInfo["phone1"]}</p>

        <div className="w-full p-2 mb-1 text-xl font-bold text-center text-black">
          ONLINE
        </div>

        <div className="w-full p-2 mb-1 text-xl font-bold text-left text-white bg-black">
          {selOrder.orderType}
        </div>

        <div className="flex justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-white bg-black">
          <p> {selOrder.payment} </p>

          {selOrder.payment === "Paypal" ? (
            <p>TxID: {selOrder.paypal_txd}</p>
          ) : null}
        </div>

        <div className="flex items-end justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-black border-black">
          <p className="text-2xl">[ #{selOrder.id} ]</p>

          <Moment format="H:mm, DD/MM/YYYY">{selOrder.created_at}</Moment>
        </div>

        <div className="my-2">
          <p className="text-xl font-semibold text-left">Order Details:</p>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Name:</p>
            <p className="font-semibold text-right">
              {selOrder.userFname} {selOrder.userLname}
            </p>
          </div>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Phone:</p>
            <p className="font-semibold text-right">{selOrder.userPhone}</p>
          </div>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Email:</p>
            <p className="font-semibold text-right">{selOrder.userEmail}</p>
          </div>
        </div>

        <div>
          <p className="text-xl font-semibold text-left">Billing:</p>
          <p className="text-base text-left">
            {selOrder.billaddress1}, {selOrder.billaddress2}
          </p>

          <p className="text-base text-left">
            {selOrder.billcity}, {selOrder.billregion}
            <span className="font-semibold"> {selOrder.billpostcode}</span>
          </p>
          {selOrder.orderType === "Delivery" ? (
            <>
              <p className="mt-2 text-xl font-semibold text-left">Delivery:</p>
              <p className="text-base text-left">
                {selOrder.deliaddress1}. {selOrder.deliaddress2}
              </p>
              <p className="text-base text-left">
                {selOrder.delicity}, {selOrder.deliregion}
                <span className="font-semibold"> {selOrder.delipostcode}</span>
              </p>
            </>
          ) : null}
        </div>

        <p className="text-2xl font-semibold text-left border-b border-black">
          Items
        </p>
        <table className="w-full mx-auto table-auto">
          <tbody>
            {cartID.map((cart, index) => (
              <tr
                key={index}
                className="my-1 border-b border-black border-dotted"
              >
                <td className="text-lg font-semibold text-left">
                  {qty[index]}x{" "}
                  <span className="font-normal">{foodName[index]} </span>
                </td>

                <td className="flex items-center justify-end gap-2">
                  £{foodPrice[index]}
                  <span>&#9744;</span>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-xl text-left">Sub Total</td>

              <td className="text-xl text-right">
                £{parseFloat(selOrder.subTotal).toFixed(2)}
              </td>
            </tr>

            {selOrder.cartConditions != null ? (
              <>
                {condId.map((cart, index) => (
                  <tr key={index}>
                    <td className="text-lg text-left">{condName[index]}</td>

                    <td>
                      <p className="text-lg text-right">{condValue[index]}</p>
                    </td>
                  </tr>
                ))}
              </>
            ) : null}

            <tr className="border-t border-black">
              <td className="text-3xl font-bold text-left">Total</td>

              <td className="text-3xl font-bold text-right">
                £{parseFloat(selOrder.total).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w-full mx-auto mt-2">
          {selOrder.comments != null ? (
            <p className="text-base italic text-left">
              Note: {selOrder.comments} asdafg
            </p>
          ) : null}

          <p className="mt-1 text-center border-t border-black">
            Thank you for ordering with us
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnlineOrderPrint;
