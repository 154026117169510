import React from "react";
import Clock from "react-live-clock";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import { useState, useEffect } from "react";
import OnlineOrderCard from "./OnlineOrderCard";
import OnlineOrderPrint from "./OnlineOrderPrint";
const OnlineOrder = ({
  apiOnlineOrders,
  apiOnlineOrderStatus,
  smokeInfo,
  activePrint,
  silentPrint,
  setOnlineOrderCount,
}) => {
  const [orderData, setOrderData] = useState([]);
  const [selOrder, setSelOrder] = useState([]);

  const [cartID, setCartID] = useState([]);
  const [active, setActive] = useState(-1);
  const [foodName, setFoodName] = useState([]);
  const [qty, setQty] = useState([]);
  const [foodPrice, setFoodPrice] = useState([]);
  const [condId, setCondId] = useState([]);
  const [condName, setCondName] = useState([]);
  const [condValue, setCondValue] = useState([]);
  const [orderStatus, setOrderStatus] = useState(-1);

  const [isRejecting, setisrejecting] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      let url = apiOnlineOrders;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };
    const getOrders = async () => {
      const data = await fetchOrders();
      setOrderData(data);
      const countAccept = data.filter((data) => data.status === 0);
      const countProcess = data.filter((data) => data.status === 1);
      const countReady = data.filter((data) => data.status === 2);
      setOnlineOrderCount({
        countAccept: countAccept.length,
        countProcess: countProcess.length,
        countReady: countReady.length,
      });
    };
    getOrders();
  }, [apiOnlineOrders, setOnlineOrderCount]);

  const showDetails = async (id) => {
    setActive(id);
    const order = orderData.filter((data) => data.id === id);
    setSelOrder(order[0]);
    setFoodName(order[0].foodName.split(","));
    setQty(order[0].foodQty.split(","));
    setFoodPrice(order[0].foodPrice.split(","));
    setOrderStatus(order[0].status);
    let arr = order[0].foodQty.split(",");
    let ids = Array.from(Array(arr.length).keys());
    setCartID(ids);

    if (order[0].cartConditions != null) {
      setCondName(order[0].cartConditions.split(","));
      setCondValue(order[0].conditionValues.split(","));

      let condArr = order[0].conditionValues.split(",");
      let condIds = Array.from(Array(condArr.length).keys());
      setCondId(condIds);
    }
  };

  //pagination//
  const [pageNumber, setPageNumber] = useState(0);
  const ordersPerPage = 25;
  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil(orderData.length / ordersPerPage);
  const displayOrders = orderData
    .slice(pagesVisited, pagesVisited + ordersPerPage)
    .map((order) => {
      return (
        <OnlineOrderCard
          order={order}
          key={order.id}
          showDetails={showDetails}
          active={active}
        ></OnlineOrderCard>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //pagination//

  const updateStatus = async (id, stat) => {
    let url = apiOnlineOrderStatus;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id,
        stat,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOrderData(res);
        const countAccept = res.filter((data) => data.status === 0);
        const countProcess = res.filter((data) => data.status === 1);
        const countReady = res.filter((data) => data.status === 2);
        setOnlineOrderCount({
          countAccept: countAccept.length,
          countProcess: countProcess.length,
          countReady: countReady.length,
        });
      });
  };

  return (
    <div>
      {!activePrint ? (
        <div>
          {selOrder != null ? (
            <div id="printThis" className="printContent">
              <OnlineOrderPrint
                smokeInfo={smokeInfo}
                selOrder={selOrder}
                cartID={cartID}
                foodName={foodName}
                qty={qty}
                foodPrice={foodPrice}
                condId={condId}
                condName={condName}
                condValue={condValue}
              ></OnlineOrderPrint>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="grid grid-cols-8">
        <div className="col-span-5 mt-16 border-t">
          <div className="p-4 ml-1 bg-white rounded-lg">
            <div className="orderlist-container">
              <div className="grid grid-cols-5 gap-2">{displayOrders}</div>
            </div>

            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={
                "bg-gray-100 rounded-md flex items-center justify-start p-4 shadow-md"
              }
              previousLinkClassName={
                "border-indigo-500 border border-b-4 p-3 font-bold rounded-l-md"
              }
              nextLinkClassName={
                "border-indigo-500 border border-b-4 p-3 font-bold rounded-r-md"
              }
              pageLinkClassName={
                "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
              }
              breakLinkClassName={
                "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
              }
              disabledClassName={"rounded-md text-gray-400"}
              activeLinkClassName={"bg-indigo-500 text-white"}
            />
          </div>
        </div>
        <div className="col-span-3 border-l">
          <div className="w-full h-screen p-1">
            <div className="relative w-full h-full px-2 bg-white rounded-md">
              <div className="flex justify-between border-b items-top">
                <h1 className="mb-1 text-xl font-bold text-left">
                  Online Order Details
                </h1>
                <Clock
                  format={"hh:mm:ssA"}
                  ticking={true}
                  timezone={"GB"}
                  className="text-base font-semibold"
                />
              </div>

              {active > 0 ? (
                <div>
                  <div className="p-2 mt-1 mb-2 border border-gray-200 rounded-md shadow-md">
                    <div className="flex items-start justify-between border-b border-gray-400">
                      <div>
                        <h4 className="text-lg font-bold text-left text-yellow-500">
                          Order [#{selOrder.id}]
                        </h4>
                        {selOrder.refundId != null ? (
                          <h4 className="text-sm font-bold text-left text-red-500">
                            Refunded
                          </h4>
                        ) : null}

                        {orderStatus === -1 ? (
                          <h4 className="text-sm font-bold text-left text-red-500">
                            Rejected
                          </h4>
                        ) : null}

                        {orderStatus === 3 ? (
                          <p className="text-sm font-bold text-left text-green-500">
                            Completed
                          </p>
                        ) : null}

                        <p className="text-left">
                          <Moment format="DD/MM/YYYY">
                            {selOrder.created_at}
                          </Moment>
                        </p>
                      </div>

                      <div>
                        <p className="text-right">
                          Order-Type:
                          <span className="ml-1 font-semibold text-blue-500">
                            {selOrder.orderType}
                          </span>
                        </p>
                        <p className="text-right">
                          Payment:
                          <span className="ml-1 font-semibold text-green-500">
                            {selOrder.payment}
                          </span>
                        </p>
                        {selOrder.payment === "Paypal" ? (
                          <p className="text-right">
                            TxID:{" "}
                            <span className="font-semibold text-blue-500">
                              {selOrder.paypal_txd}
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <div className="flex items-start justify-between gap-2">
                        <div>
                          <p className="text-sm text-left">
                            Customer:
                            <span className="ml-1 font-semibold ">
                              {selOrder.userFname} {selOrder.userLname}
                            </span>
                          </p>
                          <p className="text-sm text-left">
                            Mobile/Land:
                            <span className="ml-1 font-semibold text-red-500">
                              {selOrder.userPhone}
                            </span>
                          </p>
                          <p className="text-sm text-left">
                            Email:
                            <span className="ml-1 font-semibold ">
                              {selOrder.userEmail}
                            </span>
                          </p>
                        </div>
                        <div></div>
                      </div>
                      {/* <p className="mt-1 text-sm font-semibold text-left">
                        Billing Address:
                        <span className="ml-1 font-normal">
                          {selOrder.billaddress1} <br />
                          {selOrder.billaddress2}, {selOrder.billcity},{" "}
                          {selOrder.billregion}
                          <span className="font-semibold">
                            {" "}
                            {selOrder.billpostcode}
                          </span>
                        </span>
                      </p> */}

                      {selOrder.orderType === "Delivery" ? (
                        <p className="text-sm font-semibold text-left">
                          Delivery Address:
                          <span className="ml-1 font-normal">
                            {selOrder.deliaddress1} <br />
                            {selOrder.deliaddress2}, {selOrder.delicity},{" "}
                            {selOrder.deliregion}
                            <span className="font-semibold">
                              {" "}
                              {selOrder.delipostcode}
                            </span>
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="p-2 mb-2 border border-gray-200 rounded-md shadow-lg">
                    <div className="grid items-center grid-cols-6 gap-2 py-1 border-b-2 border-gray-300">
                      <p className="font-semibold text-left">Qty</p>
                      <p className="col-span-4 font-semibold text-left">
                        Item name
                      </p>
                      <p className="font-semibold text-center ">Price</p>
                    </div>
                    <div className="orderCart-wrapper">
                      {cartID.map((cart, index) => (
                        <div
                          key={index}
                          className="grid items-center grid-cols-6 gap-2 py-1 border-b border-gray-300 border-dashed"
                        >
                          <p className="text-left">{qty[index]}x </p>
                          <p className="col-span-4 text-left">
                            {foodName[index]}
                          </p>
                          <p className="text-center">£{foodPrice[index]}</p>
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="font-semibold">Subtotal</p>
                      <p className="font-semibold">
                        £{parseFloat(selOrder.subTotal).toFixed(2)}
                      </p>
                    </div>
                    {selOrder.cartConditions != null ? (
                      <div>
                        {condId.map((cart, index) => (
                          <div key={index}>
                            <div className="flex items-center justify-between text-yellow-600">
                              <p className="font-semibold">{condName[index]}</p>
                              <p className="font-semibold">
                                {condValue[index]}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <div className="flex items-center justify-between">
                      <p className="text-xl font-bold text-green-400">Total</p>
                      <p className="text-xl font-bold text-green-400">
                        £{parseFloat(selOrder.total).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="absolute z-20 w-11/12 ml-2 bottom-2">
                    <div className="flex items-center justify-between gap-2">
                      {orderStatus === 0 ? (
                        <div className="flex items-center justify-start gap-2">
                          <button
                            onClick={() => {
                              updateStatus(selOrder.id, 1);
                              setOrderStatus(1);
                            }}
                            className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-lg"
                          >
                            Accept
                          </button>

                          <button
                            onClick={() => {
                              setisrejecting(true);
                            }}
                            className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-white bg-red-400 border-b-4 border-red-700 rounded-lg"
                          >
                            Reject
                          </button>
                        </div>
                      ) : null}
                      {orderStatus === 1 ? (
                        <button
                          onClick={() => {
                            updateStatus(selOrder.id, 2);
                            setOrderStatus(2);
                          }}
                          className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-white bg-blue-400 border-b-4 border-blue-700 rounded-lg"
                        >
                          Ready
                        </button>
                      ) : null}

                      {orderStatus === 2 ? (
                        <button
                          onClick={() => {
                            updateStatus(selOrder.id, 3);
                            setOrderStatus(3);
                          }}
                          className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-black bg-yellow-400 border-b-4 border-yellow-700 rounded-lg"
                        >
                          Complete
                        </button>
                      ) : null}

                      {!activePrint ? (
                        <button
                          onClick={() => silentPrint()}
                          className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-white bg-indigo-400 border-b-4 border-indigo-700 rounded-lg"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                            />
                          </svg>{" "}
                          Re-Print
                        </button>
                      ) : (
                        <button className="flex items-center justify-center w-full gap-2 px-4 py-3 text-xl font-bold text-gray-600 bg-gray-400 border-b-4 border-gray-700 rounded-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                            />
                          </svg>{" "}
                          Re-Print
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="p-2 my-6 text-base font-semibold text-gray-500 bg-gray-200 rounded-lg">
                  Please select an Order from the left for details
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isRejecting ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="m-10">
            <div className="p-10 bg-white rounded-lg shadow-lg">
              <h4 className="mb-8 text-lg font-semibold text-center">
                Are you sure you want to Reject this Order?
              </h4>

              <div className="flex items-center justify-center gap-4">
                <button
                  onClick={() => {
                    updateStatus(selOrder.id, -1);
                    setOrderStatus(-1);
                    setisrejecting(false);
                  }}
                  className="px-12 py-3 text-lg font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-lg"
                >
                  Yes
                </button>

                <button
                  onClick={() => {
                    setisrejecting(false);
                  }}
                  className="px-12 py-3 text-lg font-bold text-white bg-red-400 border-b-4 border-red-700 rounded-lg"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OnlineOrder;
