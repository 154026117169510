import React, { useEffect } from "react";
import { useState } from "react";

const FoodButton = ({ food, onAdd, addNest, activeTable, toppings, toppingOptions, onToppingAdd, addNestTopping }) => {
  const getRandomColor = (id) => {
    let colors = [
      "bg-red-200",
      "bg-yell",
      "bg-green-200",
      "bg-blue-200",
      "bg-purp",
      "bg-brown",
      "bg-pink-200",
      "bg-lime",
      "bg-cyan",
    ];
    return colors[(id + 1) % colors.length];
  };
  const getRandomBorder = (id) => {
    let borders = [
      "border-red-400",
      "border-yellDark",
      "border-green-400",
      "border-blue-400",
      "border-purpDark",
      "border-brownDark",
      "border-pink-400",
      "border-limeDark",
      "border-cyanDark",
    ];
    return borders[(id + 1) % borders.length];
  };

  const isnest = food.isnest;
  const isTopping=food.isTopping;

  var subCats;
  var catList;
  var subPrice;
  var options;
  const [activeIndex,setActiveIndex]=useState(0);
  const [subActievName,setSubActiveName]=useState('');

  if(isTopping===1){
    options=food.toppingIds.split(",");
  }



  if (isnest === 1) {
    subCats = food.subcat.split(",");
    subPrice = food.subcatPrice.split(",");
    catList = subCats.map((name, index) => (
      <div key={index}>
      {isTopping===1?(
        <div 
        
        onClick={() => {setOpenTopping(!openTopping); setActiveIndex(index); setSubActiveName(name) }}
        className={`relative  transition-all duration-150 ease-out 
        ${getRandomColor(food.id + index)} 
        border-b-4 ${getRandomBorder(food.id + index)} 
        rounded-lg shadow-lg  w-28 h-28  food`}>

        <h1 className="pt-2 pl-2 pr-2 font-semibold leading-none text-left">
          {name} 
        </h1>
        <div className="flex items-center justify-between w-full gap-2 px-2 pb-1 mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
              </svg>
            </div>
        <div className="absolute bottom-0 flex items-center justify-between w-full gap-2 px-2 pb-1">
            <h1 className="text-lg font-bold">
              £{subPrice[index]}
            </h1>
        </div>
        </div>
        )
        :
        (
          <div
            
            onClick={() =>
              addNest(food.id, name, subPrice[index], index, activeTable)
            }
            className={`relative mt-2 mr-1 font-bold ${getRandomColor(food.id + index)}  
            border-b-4 ${getRandomBorder(food.id + index)} rounded-lg w-28 h-28 food`}>

            <h1 className="p-3 font-semibold leading-none text-left"> {name} </h1>
            <h1 className="absolute bottom-0 right-0 p-3 text-lg font-bold">
              £{subPrice[index]}
            </h1>
          </div>
        )
      }
      </div>
    ));
  }

  const [open, setOpen] = useState(false);

  const [openTopping, setOpenTopping]=useState(false);

  const [selectedToppings,setSelectedToppings]=useState([-1]);

  // useEffect(()=>{
  //   if(isTopping===1){
  //     var temp=[];
  //     temp=toppings.map((tops)=>({...tops, selected:0}))

  //   }
  //   setSelectedToppings(temp);
  // },[openTopping])

  const updateSelection=(key, value)=>{
    if(!selectedToppings.includes(value)){
      setSelectedToppings(selectedToppings=>[...selectedToppings, value]);
    }else{
      setSelectedToppings(selectedToppings.filter(val=>val!=value))
    }

    // var temp= selectedToppings.map((temp)=>
    // temp.id==key?
    // {
    //   ...temp,
    //   selected:parseInt(value)
    // }:temp
    // )
    // setSelectedToppings()
    
  }

  const applyTopping=()=>{
    if(isnest===1){
      addNestTopping(food.id, subActievName, subPrice[activeIndex], activeIndex, activeTable, selectedToppings)
    }else{
      onToppingAdd(food.id, activeTable, selectedToppings)
    }
    setOpenTopping(!openTopping);

    setSelectedToppings([-1]);
  }

  return (
    <div>
      {isnest === 1 ? (
        <div
          onClick={() => setOpen(!open)}
          className={` ${getRandomColor(food.id)} border-b-4 ${getRandomBorder(food.id)} rounded-lg shadow-lg w-28 h-28 food`}>
          <h1 className="p-3 font-semibold leading-none text-left">
            {food.name}
          </h1>
          <div className="flex items-center justify-between w-full gap-2 px-2 pb-1">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
            </svg>  
          </div>
          {open ? (
            <div className="absolute top-0 left-0 z-50 w-screen h-screen bg-gray-300 backdrop-filter backdrop-blur-sm bg-opacity-30">
              <div className="p-6 m-4 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold text-left text-gray-900">
                  {food.name}
                </h1>
                
                <div className="flex flex-wrap items-center justify-start gap-2 mt-4">{catList}</div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
        {isTopping==1?
        (
          <div onClick={() => setOpenTopping(!openTopping)}
          className={`relative  transition-all duration-150 ease-out ${getRandomColor(
            food.id
            )} border-b-4 ${getRandomBorder(
              food.id
              )} rounded-lg shadow-lg  w-28 h-28  food`}
              >
            <h1 className="pt-2 pl-2 pr-2 font-semibold leading-none text-left">
              {food.name} 
            </h1>

            <div className="flex items-center justify-between w-full gap-2 px-2 pb-1 mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
              </svg>
            </div>
            <div className="absolute bottom-0 flex items-center justify-between w-full gap-2 px-2 pb-1">
              
              <h1 className="text-lg font-bold">
                £{food.price}
              </h1>
            </div>
            
          </div>
        )
        :
        (
          <div onClick={() => onAdd(food.id, activeTable)}
          className={`relative  transition-all duration-150 ease-out ${getRandomColor(
            food.id
            )} border-b-4 ${getRandomBorder(
              food.id
              )} rounded-lg shadow-lg w-28 h-28 food`}
              >
            <h1 className="pt-2 pl-2 pr-2 font-semibold leading-none text-left">
              {food.name}
            </h1>
            <h1 className="absolute bottom-0 right-0 pb-1 pr-2 text-lg font-bold">
              £{food.price}
            </h1>
          </div>
        )
          }
        </>
      )}



    {openTopping ? (
      <div className="absolute top-0 left-0 z-50 w-screen h-screen bg-gray-300 backdrop-filter backdrop-blur-sm bg-opacity-30">
        <div className="p-6 m-4 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between gap-2">
          <h1 className="text-2xl font-bold text-left text-gray-900">
            {food.name} - Toppings
          </h1>

          <button
                onClick={() => setOpenTopping(!openTopping)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

          </div>
          

          <hr></hr>
          {toppings.map((tops)=>(
            <div key={tops.id}>
              <h1 className="text-xl font-bold text-left text-gray-900">
                {tops.name} 
              </h1>
              <div className="flex flex-wrap">
              {/* <div
                onClick={()=>{updateSelection(tops.id, 0)}}
                  className={`relative mt-2 mr-1 font-bold bg-gray-200 border-b-4 border-gray-400 ${selectedToppings.filter(t=>t.id==tops.id)[0].selected==0? `ring ring-blue-500` : `ring-0`} rounded-lg w-20 h-20 food`}>
                      <h1 className="p-1 text-sm font-semibold leading-none text-left">None</h1>
                </div> */}
               {options.map((opKey)=>(
                <div key={opKey+tops.id}>
                {toppingOptions[opKey].toppingId==tops.id? (
                  <div key={opKey}
                  onClick={()=>{updateSelection(tops.id, opKey)}}
                  className={`relative mt-2 mx-1 font-bold 
                  ${getRandomColor(tops.id+opKey)}  border-b-4 
                  ${getRandomBorder(tops.id+opKey)}  rounded-lg w-20 h-20 food
                  ${selectedToppings.includes(opKey)? `ring ring-blue-500` : ``}`}>
                      <h1 className="p-1 text-sm font-semibold leading-none text-left"> {toppingOptions[opKey].name}</h1>
                      
                      <h1 className="absolute bottom-0 right-0 p-1 font-bold">
                      £{toppingOptions[opKey].price}
                    </h1>
                  </div>
                  ):null
                }
                </div>
               ))}
              </div>

            </div>
            
            ))}
            <div className="flex items-center justify-start">
            <button 
            onClick={()=>{applyTopping()}}
            className="p-5 px-8 mt-4 font-semibold text-left text-green-700 bg-green-300 border-b-4 border-green-400 rounded-lg"> Apply </button>
            </div>
        </div>
      </div>
    ) : null}

    </div>
  );
};
export default FoodButton;
