import React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../images/ezpoz-logo.png";
import LogoPower from "../images/logoweb.png";
import { useState } from "react";
import shutDown from "../images/shutDown.png";
import Clock from "react-live-clock";

const Welcome = ({ smokeInfo }) => {
  let history = useHistory();
  const [openShut, setOpenShut] = useState(false);

  const closeApp = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);
  const pinInput = (value) => {
    setPin(pin + value);
  };

  const pinInputDel = () => {
    setPin(pin.slice(0, -1));
    setError(false);
  };

  const pinInputClear = () => {
    setPin("");
    setError(false);
  };

  const logIn = () => {
    if (smokeInfo[0].pin === parseInt(pin)) {
      history.push("/epos/miyabi");
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <div className="relative h-screen border rounded-xl ezpos">
          <div className="flex flex-wrap items-center justify-center mt-56 ">
            <div>
              <div className="h-auto w-44">
                <img src={Logo} alt="EZPos" />
              </div>
              <p className="mt-4 text-center">Powered by;</p>
              <div className="h-auto w-44">
                <img src={LogoPower} alt="websiteDevelopersHub" />
              </div>
            </div>
          </div>
          <div className="absolute left-0 p-4 bottom-10">
            <div className="flex justify-start mt-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
              </div>
              <p className="ml-2">020 8144 0304</p>
            </div>

            <div className="flex justify-start mt-1">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="ml-2">support@websitedevelopershub.com</p>
            </div>

            <div className="flex justify-start mt-1">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="ml-2">www.websitedevelopershub.com</p>
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex justify-end pr-2">
            <Clock
              format={"hh:mm:ssA"}
              ticking={true}
              timezone={"GB"}
              className="text-2xl font-bold"
            />
          </div>
          <div className="flex justify-center items-top">
            <div className="mt-16 bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
              {error ? (
                <h4 className="mt-2 text-2xl font-semibold text-red-400">
                  Wrong Pin!
                </h4>
              ) : (
                <h4 className="mt-2 text-2xl font-semibold text-white">
                  Enter Pin Number
                </h4>
              )}
              <div className="flex items-center justify-between m-3">
                <input
                  type="password"
                  value={pin}
                  className={`w-full p-2 m-2 text-2xl font-bold rounded-lg shadow-lg h-14 ${
                    error ? `border-4 bg-red-200 border-red-500` : ""
                  } `}
                  readOnly
                />
              </div>

              <div className="grid grid-cols-3 m-3">
                <button
                  value="1"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  1
                </button>
                <button
                  value="2"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  2
                </button>
                <button
                  value="3"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  3
                </button>

                <button
                  value="4"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  4
                </button>
                <button
                  value="5"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  5
                </button>
                <button
                  value="6"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  6
                </button>

                <button
                  value="7"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  7
                </button>
                <button
                  value="8"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  8
                </button>
                <button
                  value="9"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  9
                </button>

                <button
                  value="."
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  .
                </button>
                <button
                  value="0"
                  onClick={(e) => pinInput(e.target.value)}
                  className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
                >
                  0
                </button>
                <button
                  onClick={() => pinInputDel()}
                  className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
                >
                  del
                </button>
              </div>
              <div className="grid grid-cols-2 m-3">
                <button
                  onClick={() => pinInputClear()}
                  className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
                >
                  Clear
                </button>
                <button
                  onClick={() => logIn()}
                  className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
                >
                  Login
                </button>
              </div>
              <p className="mt-8 text-sm text-center text-gray-600">
                Forgot pin? Contact your admin to reset it from admin dashboard{" "}
                <br /> or contact Webhub Team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 pb-10 pr-10">
        <button
          onClick={() => {
            setOpenShut(true);
          }}
          className="px-2 py-1 bg-red-500 border border-b-4 border-red-800 rounded-full w-14 h-14"
        >
          <img src={shutDown} alt="" />
        </button>
      </div>

      {openShut ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="m-10">
            <div className="p-10 bg-white rounded-lg shadow-lg">
              <h4 className="mb-8 text-lg font-semibold text-center">
                Are you sure you want to Close?
              </h4>

              <div className="flex items-center justify-center gap-4">
                <button
                  onClick={() => {
                    closeApp();
                  }}
                  className="px-12 py-3 text-lg font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-lg"
                >
                  Yes
                </button>

                <button
                  onClick={() => {
                    setOpenShut(false);
                  }}
                  className="px-12 py-3 text-lg font-bold text-white bg-red-400 border-b-4 border-red-700 rounded-lg"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Welcome;
