import React from "react";
import Clock from "react-live-clock";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import { useState, useEffect } from "react";
import OrderCard from "./OrderCard";
import OrderPrint from "../components/OrderPrint";

const Orders = ({
  smokeInfo,
  activePrint,
  apiOrders,
  apiCustomer,
  apiRefund,
  silentPrint,
}) => {
  const [orderData, setOrderData] = useState([]);
  const [selOrder, setSelOrder] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [cusData, setCusData] = useState([]);

  const [active, setActive] = useState(-1);

  const [cartID, setCartID] = useState([]);
  const [foodName, setFoodName] = useState([]);
  const [qty, setQty] = useState([]);
  const [foodSum, setFoodSum] = useState([]);

  const [openRefund, setOpenRefund] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      let url = apiOrders;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };
    const getOrders = async () => {
      const data = await fetchOrders();
      setOrderData(data);
    };
    getOrders();
  }, [apiOrders, openRefund]);

  useEffect(() => {
    const fetchSmokeusers = async () => {
      let url = apiCustomer;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const getSmokeUsers = async () => {
      const smokeData = await fetchSmokeusers();
      setCustomers(smokeData);
    };

    getSmokeUsers();
  }, [apiCustomer]);

  const showDetails = async (id) => {
    setActive(id);
    const order = orderData.filter((data) => data.id === id);
    setSelOrder(order[0]);
    setCartID(order[0].cartID.split(","));
    setFoodName(order[0].foodname.split(","));
    setQty(order[0].foodQuantity.split(","));
    setFoodSum(order[0].foodPriceSum.split(","));
    if (order[0].userID > 0) {
      const customer = customers.filter((data) => data.id === order[0].userID);
      setCusData(customer);
    } else {
      setCusData([]);
    }
  };
  //pagination//
  const [pageNumber, setPageNumber] = useState(0);
  const ordersPerPage = 25;
  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil(orderData.length / ordersPerPage);
  const displayOrders = orderData
    .slice(pagesVisited, pagesVisited + ordersPerPage)
    .map((order) => {
      return (
        <OrderCard
          order={order}
          key={order.id}
          showDetails={showDetails}
          active={active}
        ></OrderCard>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //pagination//

  const refundOrder = async (id) => {
    let url = apiRefund;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    }).then(() => {
      setOpenRefund(false);
    });
  };
  // const handlePrint = () => {
  //   var divContents = document.getElementById("printThis").innerHTML;
  //   var a = window.open("", "", "height=500, width=302");
  //   a.document.write("<html>");
  //   a.document.write(
  //     '<head><link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">'
  //   );
  //   a.document.write(
  //     "<style> html, body {width: 80mm; height:100%;position:absolute;}</style></head>"
  //   );
  //   a.document.write("<body >");
  //   a.document.write(divContents);
  //   a.document.write("</body></html>");
  //   a.document.close();
  //   a.print();
  //   a.window.close();
  // };
  return (
    <div>
      {!activePrint ? (
        <div id="printThis" className="printContent">
          <OrderPrint
            smokeInfo={smokeInfo}
            selOrder={selOrder}
            cusData={cusData}
            cartID={cartID}
            foodName={foodName}
            qty={qty}
            foodSum={foodSum}
          ></OrderPrint>
        </div>
      ) : null}
      <div className="grid grid-cols-8">
        <div className="col-span-5 mt-16 border-t">
          <div>
            <div className="p-4 ml-1 bg-white rounded-lg">
              <div className="orderlist-container">
                <div className="grid grid-cols-5 gap-2">{displayOrders}</div>
              </div>

              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={
                  "bg-gray-100 rounded-md flex items-center justify-start p-4 shadow-md"
                }
                previousLinkClassName={
                  "border-indigo-500 border border-b-4 p-3 font-bold rounded-l-md"
                }
                nextLinkClassName={
                  "border-indigo-500 border border-b-4 p-3 font-bold rounded-r-md"
                }
                pageLinkClassName={
                  "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
                }
                breakLinkClassName={
                  "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
                }
                disabledClassName={"rounded-md text-gray-400"}
                activeLinkClassName={"bg-indigo-500 text-white"}
              />
            </div>
          </div>
        </div>

        <div className="col-span-3 border-l">
          <div className="w-full h-screen p-1">
            <div className="relative w-full h-full px-2 bg-white rounded-md">
              <div className="flex justify-between border-b items-top">
                <h1 className="mb-1 text-xl font-bold text-left">
                  Order Details
                </h1>
                <Clock
                  format={"hh:mm:ssA"}
                  ticking={true}
                  timezone={"GB"}
                  className="text-base font-semibold"
                />
              </div>
              {cartID.length > 0 ? (
                <div>
                  <div className="p-2 mt-1 mb-2 border border-gray-200 rounded-md shadow-md">
                    <div className="flex items-center justify-between border-b border-gray-400">
                      <div>
                        <h4 className="text-lg font-bold text-left text-yellow-500">
                          Order [#{selOrder.id}]
                        </h4>
                        {selOrder.refund > 0 ? (
                          <h4 className="text-sm font-bold text-left text-red-500">
                            Refunded
                          </h4>
                        ) : null}
                        <p className="text-left">
                          <Moment format="DD/MM/YYYY">
                            {selOrder.created_at}
                          </Moment>
                        </p>
                      </div>
                      <div>
                        <p className="text-right">
                          Order-Type:
                          <span className="ml-1 font-semibold text-blue-500">
                            {selOrder.orderType}
                          </span>
                        </p>
                        <p className="text-right">
                          Payment:
                          <span className="ml-1 font-semibold text-green-500">
                            {selOrder.payment}
                          </span>
                        </p>
                      </div>
                    </div>
                    {cusData.length > 0 ? (
                      <div>
                        <p className="text-left">
                          Customer:
                          <span className="ml-1 font-semibold ">
                            {cusData[0].fname} {cusData[0].lname}
                          </span>
                        </p>
                        <p className="text-left">
                          Mobile/Land:
                          <span className="ml-1 font-semibold ">
                            {cusData[0].phone}
                          </span>
                        </p>
                        <p className="text-left">
                          Email:
                          <span className="ml-1 font-semibold ">
                            {cusData[0].email}
                          </span>
                        </p>
                        <p className="text-left">
                          Address:
                          <span className="ml-1 font-semibold ">
                            {cusData[0].house} {cusData[0].street}{" "}
                            {cusData[0].city} {cusData[0].postcode}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <p className="text-left">
                        Customer:
                        <span className="ml-1 font-semibold ">Random User</span>
                      </p>
                    )}

                    {selOrder.orderType === "Eat-In" ? (
                      <div className="flex justify-between w-full gap-2 text-base font-bold text-left">
                        <p>Table no. {selOrder.tableNo + 1} </p>
                        <p> Serving. {selOrder.serving} </p>
                      </div>
                    ) : null}
                  </div>

                  <div className="p-2 mb-2 border border-gray-200 rounded-md shadow-lg">
                    <div className="grid items-center grid-cols-6 gap-2 py-1 border-b-2 border-gray-300">
                      <p className="font-semibold text-left">Qty</p>
                      <p className="col-span-4 font-semibold text-left">
                        Item name
                      </p>
                      <p className="font-semibold text-right ">Total</p>
                    </div>
                    <div className="orderCart-wrapper">
                      {cartID.map((cart, index) => (
                        <div
                          key={index}
                          className="grid items-center grid-cols-6 gap-2 py-1 border-b border-gray-300 border-dashed "
                        >
                          <p className="text-left">{qty[index]}x </p>
                          <p className="col-span-4 text-left">
                            {foodName[index]}
                          </p>
                          {/* <p className="text-center">£{foodPrice[index]}</p> */}
                          <p className="text-right">£{foodSum[index]}</p>
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="font-semibold">Subtotal</p>
                      <p className="font-semibold">£{selOrder.subTotal}</p>
                    </div>

                    {selOrder.appliedTotal ? (
                      <div>
                        {selOrder.cashDisc > 0 ? (
                          <div className="flex items-center justify-between">
                            <p className="font-semibold text-red-500">
                              Discount
                            </p>
                            <p className="font-semibold text-red-500">
                              -£{selOrder.cashDisc}
                            </p>
                          </div>
                        ) : null}

                        {selOrder.percDisc > 0 ? (
                          <div className="flex items-center justify-between">
                            <p className="font-semibold text-red-500">
                              Discount
                            </p>
                            <p className="font-semibold text-red-500">
                              -{selOrder.percDisc}%
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="flex items-center justify-between">
                      <p className="text-xl font-bold text-green-400">Total</p>
                      <p className="text-xl font-bold text-green-400">
                        £{selOrder.total}
                      </p>
                    </div>

                    {selOrder.payment === "Cash" ? (
                      <>
                        <div className="flex items-center justify-between border-t">
                          <p className="text-base font-bold text-blue-500">
                            Paid
                          </p>
                          <p className="text-base font-bold text-blue-500">
                            £{selOrder.paid}
                          </p>
                        </div>

                        <div className="flex items-center justify-between">
                          <p className="text-base font-bold text-red-500">
                            Change
                          </p>
                          <p className="text-base font-bold text-red-500">
                            £{selOrder.changes}
                          </p>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="absolute z-20 flex justify-between left-7 w-80 bottom-2">
                    {!activePrint ? (
                      <button
                        onClick={() => silentPrint()}
                        className="flex items-center justify-center gap-2 px-4 py-3 text-xl font-bold text-white bg-blue-400 border-b-4 border-blue-700 rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                          />
                        </svg>{" "}
                        Re-Print
                      </button>
                    ) : (
                      <button className="flex items-center justify-center gap-2 px-4 py-3 text-xl font-bold text-gray-600 bg-gray-400 border-b-4 border-gray-700 rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                          />
                        </svg>{" "}
                        Re-Print
                      </button>
                    )}
                    {selOrder.refund !== 1 ? (
                      <button
                        onClick={() => {
                          setOpenRefund(true);
                        }}
                        className="flex items-center justify-center gap-2 px-4 py-3 text-xl font-bold text-white bg-red-400 border-b-4 border-red-700 rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                        Refund
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : (
                <p className="p-2 my-6 text-base font-semibold text-gray-500 bg-gray-200 rounded-lg">
                  Please select an Order from the left for details
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {openRefund ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="m-10">
            <div className="p-10 bg-white rounded-lg shadow-lg">
              <h4 className="mb-8 text-lg font-semibold text-center">
                Are you sure you want to refund?
              </h4>

              <div className="flex items-center justify-center gap-4">
                <button
                  onClick={() => refundOrder(selOrder.id)}
                  className="px-12 py-3 text-lg font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-lg"
                >
                  Yes
                </button>

                <button
                  onClick={() => {
                    setOpenRefund(false);
                  }}
                  className="px-12 py-3 text-lg font-bold text-white bg-red-400 border-b-4 border-red-700 rounded-lg"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Orders;
