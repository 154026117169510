import React from "react";
import Moment from "react-moment";

const OrderCard = ({ order, active, showDetails }) => {
  return (
    <div>
      <div
        key={order.id}
        onClick={() => {
          showDetails(order.id);
        }}
        className={`w-full p-2  ${
          active === order.id
            ? `bg-yellow-200 border-yellow-400`
            : `bg-blue-50 border-blue-300`
        } border border-b-4 rounded-md shadow h-28 w-28 relative  `}
      >
        <h4 className="text-xl font-semibold text-left">{order.id}</h4>
        <p className="text-sm text-left">
          <Moment format="DD,MMM-YY">{order.created_at}</Moment>
        </p>
        <p className="text-sm font-semibold text-left ">{order.orderType}</p>
        <h1
          className={`absolute text-lg font-bold ${
            active === order.id ? `text-green-500` : `text-blue-500`
          } bottom-1 right-1`}
        >
          £{order.total}
        </h1>
        {/* <div className="grid grid-cols-2">
					<div>
						<h4 className="text-base font-semibold text-left">
							Order [#{order.id}]
						</h4>

						<p className="text-left">
							<Moment format="DD/MM/YYYY">{order.created_at}</Moment>
						</p>

						<p className="text-base text-left">
							Sub Total:
							<span className="ml-1 font-semibold text-blue-600">
								£{order.subTotal}
							</span>
						</p>
					</div>

					<div>
						{order.appliedTotal ? (
							<p className="text-left">
								Discount:
								{order.cashDisc > 0 ? (
									<span className="ml-1 font-semibold text-red-500">
										-£{order.cashDisc}
									</span>
								) : null}
								{order.percDisc > 0 ? (
									<span className="ml-1 font-semibold text-red-500">
										-{order.percDisc}%
									</span>
								) : null}
							</p>
						) : (
							<p className="text-left">
								Discount:
								<span className="ml-1 text-gray-400">NA</span>
							</p>
						)}
						<p className="text-xl font-semibold text-left">
							Total:
							<span className="ml-1 text-green-500">£{order.total}</span>
						</p>

						{order.refund > 0 ? (
							<p className="text-sm text-left text-red-500">Refunded</p>
						) : null}
					</div>
				</div> */}
      </div>
    </div>
  );
};

export default OrderCard;
