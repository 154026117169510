import React from "react";
import "./App.css";

// import Navbar from './components/navbar'
import Category from "./components/Category";
import Food from "./components/food";
import Cart from "./components/Cart";
import Welcome from "./components/Welcome";
import CustomerList from "./components/CustomerList";
import Navs from "./components/Navs";
import Orders from "./components/Orders";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Report from "./components/Report";

import TableButton from "./components/ui/TableButton";

import OnlineOrder from "./components/OnlineOrder";
import OnlineOrderPrint from "./components/OnlineOrderPrint";
import ReportOnline from "./components/ReportOnline";

//Notification
import useSound from "use-sound";
import Notify from "../src/notification/notification.mp3";
//Notification

function App() {
  //smoke shack//
  const [smokeCats, setSmokeCats] = useState([]);
  const [smokeFoods, setSmokeFoods] = useState([]);
  const [FilterSmokeFoods, setFilterSmokeFoods] = useState([]);
  const [smokeCart, setSmokeCart] = useState([]);
  const [toppings, setToppings]=useState([]);
  const [toppingOptions, setToppingOptions]=useState([]);
  //const [smokeSubTotal, setSmokeSubTotal] = useState(0.0);

  const [smokeInfo, setSmokeInfo] = useState([]);

  const [isSaveClient, setisSaveClient] = useState(0);

  const [activeCategory, setActiveCategory] = useState();

  const [activePrint, setActivePrint] = useState(false);
  const [directPrintOrder, setDirectPrintOrder] = useState([]);
  const [printCartId, setPrintCartId] = useState([]);
  const [printFoodName, setPrintFoodName] = useState([]);
  const [printQty, setPrintQty] = useState([]);
  const [printPrice, setPrintPrice] = useState([]);
  const [printCondId, setPrintCondId] = useState([]);
  const [printCondName, setPrintCondName] = useState([]);
  const [printCondValue, setPrintCondValue] = useState([]);

  const [onlineOrderCount, setOnlineOrderCount] = useState([]);

  //table//
  const TABLE_NO = 11;
  const [tables, setTables] = useState([]);
  const [activeTable, setActiveTable] = useState(99);
  useEffect(() => {
    var Table = [];
    var Cart = [];
    for (var i = 0; i < TABLE_NO + 1; i++) {
      Table.push({
        id: i,
        serving: 1,
        active: false,
        orderType: "",
      });
      Cart.push({
        id: i,
        items: [],
        clientID: -1,
        clientFname: "",
        clientLname: "",
        clientEmail: "",
        clientPhone: "",
        clientHouse: "",
        clientStreet: "",
        clientCity: "",
        clientPostcode: "",
      });
    }

    setTables(Table);
    setSmokeCart(Cart);
    setActiveTable(0);
  }, []);

  // useEffect(() => {
  //   if (orderType !== "Eat-In") {
  //     tables.map((tab) =>
  //       !tab.active ? setActiveTable(tab.id) : console.log("no free table")
  //     );
  //   } else {
  //     setActiveTable(0);
  //   }
  // }, [orderType, tables]);

  //table//

  //notification
  const [play, { stop }] = useSound(Notify);
  const NotfiyMINUTE_MS = 2000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (onlineOrderCount.countAccept > 0) {
        play();
      } else {
        stop();
      }
    }, NotfiyMINUTE_MS);

    return () => clearInterval(interval);
  }, [onlineOrderCount.countAccept, play, stop]);
  //notification

  //apis
  const apiSite = "https://florencepizza.co.uk/api/siteInfo";
  //mainApp
  const apiCategory = "https://florencepizza.co.uk/api/category";
  const apiProducts = "https://florencepizza.co.uk/api/prods";
  const apiToppings="https://florencepizza.co.uk/api/toppings";
  const apiDiscount = "https://florencepizza.co.uk/api/discount";
  const apiCharge = "https://florencepizza.co.uk/api/charge";
  const apiPostOrder =
    "https://florencepizza.co.uk/api/epoz/postOrder";
  //mainApp

  //orders//
  const apiOrders = "https://florencepizza.co.uk/api/epos/orders";
  const apiRefund = "https://florencepizza.co.uk/api/order/refund";

  //orders//

  //onlineOrders//
  const apiOnlineOrders =
    "https://florencepizza.co.uk/api/epos/onlineOrders";
  const apiOrderCount =
    "https://florencepizza.co.uk/api/epos/onlineOrders/count";
  const apiOnlineOrderStatus =
    "https://florencepizza.co.uk/api/epos/onlineOrder/status";
  //onlineOrders//

  //customers//
  const apiRegCustomer =
    "https://florencepizza.co.uk/api/epos/regCustomer";
  const apiCustomer = "https://florencepizza.co.uk/api/customers";
  //customers

  //*****report is dynamic go to report.js file and change the domains*********//

  const apiDirectPrint =
    "https://florencepizza.co.uk/api/epos/onlineOrder/fetch";
  const apiDirectPrintUpdate =
    "https://florencepizza.co.uk/api/epos/onlineOrder/updatePrint";

  //apis

  //SILENT PRINT
  const silentPrint = () => {
    window.postMessage({
      myTypeField: "my-custom-message",
    });
  };
  //SILENT PRINT

  //direct print
  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(async () => {
      const url = apiDirectPrint;
      const res = await fetch(url);
      const data = await res.json();

      if (data !== 0) {
        setDirectPrintOrder(data);
        setPrintFoodName(data.foodName.split(","));
        setPrintQty(data.foodQty.split(","));
        setPrintPrice(data.foodPrice.split(","));
        let arr = data.foodQty.split(",");
        let ids = Array.from(Array(arr.length).keys());
        setPrintCartId(ids);

        if (data.cartConditions != null) {
          setPrintCondName(data.cartConditions.split(","));
          setPrintCondValue(data.conditionValues.split(","));
          let condArr = data.conditionValues.split(",");
          let condIds = Array.from(Array(condArr.length).keys());
          setPrintCondId(condIds);
        }
        setActivePrint(true);
        silentPrint();
        //after print
        window.onafterprint = function () {
          ClearDirect(data.id);

          const getOnlineOrderCount = async () => {
            const data = await fetchOrderCount();
            setOnlineOrderCount(data);
          };
          getOnlineOrderCount();
        };
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const ClearDirect = async (id) => {
    let url = apiDirectPrintUpdate;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    }).then(() => {
      setDirectPrintOrder([]);
      setPrintFoodName([]);
      setPrintQty([]);
      setPrintPrice([]);
      setPrintCartId([]);
      setPrintCondName([]);
      setPrintCondValue([]);
      setPrintCondId([]);
      setActivePrint(false);
    });
  };
  //direct print

  const fetchOrderCount = async () => {
    const url = apiOrderCount;
    const res = await fetch(url);
    const data = await res.json();
    return data;
  };
  useEffect(() => {
    const getOnlineOrderCount = async () => {
      const data = await fetchOrderCount();
      setOnlineOrderCount(data);
    };
    getOnlineOrderCount();
  }, []);

  const fetchSmokeInfo = async () => {
    const url = apiSite;
    const res = await fetch(url);
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    const getSmokeInfo = async () => {
      const data = await fetchSmokeInfo();
      setSmokeInfo(data);
    };
    getSmokeInfo();
  }, []);

  useEffect(() => {
    const getSmokeCats = async () => {
      const smokeCats = await fetchSmokeCats();
      setSmokeCats(smokeCats);
    };
    getSmokeCats();
  }, []);

  const fetchSmokeCats = async () => {
    const res = await fetch(apiCategory);
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    const getSmokeFoods = async () => {
      const smokeFoods = await fetchSmokefoods();
      setSmokeFoods(smokeFoods);
      setFilterSmokeFoods([]);
    };
    getSmokeFoods();
  }, []);

  const fetchToppings=async()=>{
    const res=await fetch(apiToppings);
    return res.json();
  }

  useEffect(()=>{
    const getToppings= async()=>{
      const toppings=await fetchToppings();
      setToppings(toppings.toppings);
      setToppingOptions(toppings.options);
    }
    getToppings();
  },[])

  const fetchSmokefoods = async () => {
    const res = await fetch(apiProducts);
    const data = await res.json();
    return data;
  };

  const filterSmokeFood = (id) => {
    const catFood = smokeFoods.filter((food) => food.category === id);
    setFilterSmokeFoods(catFood);
  };

  const increaseSmokeCartItem = (id, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;
    const update = cart.map((item) =>
      item.id === id
        ? {
            ...item,
            priceSum: item.priceSum + item.price,
            quantity: item.quantity + 1,
          }
        : item
    );
    cartNum[0].items = update;
    setSmokeCart([...smokeCart]);

    //   setSmokeCart(
    //   smokeCart.map((item) =>
    //     item.id === id
    //       ? {
    //           ...item,
    //           priceSum: item.priceSum + item.price,
    //           quantity: item.quantity + 1,
    //         }
    //       : item
    //   )
    // );
    //const food = smokeCart.filter((item) => item.id === id);
    // updateSmokeSubtotal(food[0].price);
  };

  const increaseSmokeCartNestItem = (id, index, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;

    const update = cart.map((item) =>
      item.id === id && item.index === index
        ? {
            ...item,
            priceSum: item.priceSum + item.price,
            quantity: item.quantity + 1,
          }
        : item
    );
    cartNum[0].items = update;
    setSmokeCart([...smokeCart]);

    // setSmokeCart(
    //   smokeCart.map((item) =>
    //     item.id === id && item.index === index
    //       ? {
    //           ...item,
    //           priceSum: item.priceSum + item.price,
    //           quantity: item.quantity + 1,
    //         }
    //       : item
    //   )
    // );
    // const food = smokeCart.filter(
    //   (item) => item.id === id && item.index === index
    // );
    // updateSmokeSubtotal(food[0].price);
  };

  const decreaseSmokeCartItem = (id, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;
    const update = cart.map((item) =>
      item.id === id && item.quantity - 1 !== 0
        ? {
            ...item,
            priceSum: item.priceSum - item.price,
            quantity: item.quantity - 1,
          }
        : item
    );
    cartNum[0].items = update;
    setSmokeCart([...smokeCart]);

    // setSmokeCart(
    //   smokeCart.map((item) =>
    //     item.id === id && item.quantity - 1 !== 0
    //       ? {
    //           ...item,
    //           priceSum: item.priceSum - item.price,
    //           quantity: item.quantity - 1,
    //         }
    //       : item
    //   )
    // );

    // const food = smokeCart.filter((item) => item.id === id);
    // if (food[0].quantity - 1 !== 0) {
    // reduceSmokeSubtotal(food[0].price);
    // }
  };

  const decreaseSmokeCartNestItem = (id, index, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;

    const update = cart.map((item) =>
      item.id === id && item.index === index && item.quantity - 1 !== 0
        ? {
            ...item,
            priceSum: item.priceSum - item.price,
            quantity: item.quantity - 1,
          }
        : item
    );
    cartNum[0].items = update;
    setSmokeCart([...smokeCart]);

    // setSmokeCart(
    //   smokeCart.map((item) =>
    //     item.id === id && item.quantity - 1 !== 0 && item.index === index
    //       ? {
    //           ...item,
    //           priceSum: item.priceSum - item.price,
    //           quantity: item.quantity - 1,
    //         }
    //       : item
    //   )
    // );

    //const food = smokeCart.filter((item) => item.id === id);
    //if (food[0].quantity - 1 !== 0) {
    // reduceSmokeSubtotal(food[0].price);
    //}
  };

  // const updateSmokeSubtotal = (price) => {
  //   const total = smokeSubTotal + price;
  //   setSmokeSubTotal(total);
  // };

  // const reduceSmokeSubtotal = (price) => {
  //   const total = smokeSubTotal - price;
  //   setSmokeSubTotal(total);
  // };

  const addToSmokeCart = (id, table) => {
    const food = smokeFoods.filter((food) => food.id === id);
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items.filter((food) => food.foodId === id);
    if (cart.length > 0) {
      increaseSmokeCartItem(cart[0].id, table);
    } else {
      var newFood = {
        id: Math.floor(Math.random() * 1000) + Date.now().toString(36),
        foodId: food[0].id,
        name: food[0].name,
        price: food[0].price,
        quantity: 1,
        nest: false,
        priceSum: food[0].price,
      };
      cartNum[0].items = [...cartNum[0].items, newFood];

      setTables(
        tables.map((tab) =>
          tab.id === table
            ? {
                ...tab,
                active: true,
                orderType: "Takeaway",
              }
            : tab
        )
      );
      // setSmokeCart([...smokeCart]);
      // updateSmokeSubtotal(food[0].price);
      setSmokeCart([...smokeCart]);
      // const cartt = smokeCart.filter((item) => item.id === table);
      // console.log(cartt[0]);
      // console.log(smokeCart);
    }
  };


  const addToSmokeCartWithTopping = (id, table, toppings) => {
    const food = smokeFoods.filter((food) => food.id === id);
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items.filter((food) => food.foodId === id);
    var add=0;
    var name='';
    toppings.map((t)=>{
      if(t>0){
          add=add+parseFloat(toppingOptions[t].price)
          name=name+'-'+toppingOptions[t].name
        }
      })
    
    if (cart.length > 0) {
      increaseSmokeCartItem(cart[0].id, table);
    } else {
      var newFood = {
        id: Math.floor(Math.random() * 1000) + Date.now().toString(36),
        foodId: food[0].id+name,
        name: food[0].name+name,
        price: parseFloat(food[0].price)+add,
        quantity: 1,
        nest: false,
        priceSum: parseFloat(food[0].price)+add,
      };
      cartNum[0].items = [...cartNum[0].items, newFood];

      setTables(
        tables.map((tab) =>
          tab.id === table
            ? {
                ...tab,
                active: true,
                orderType: "Takeaway",
              }
            : tab
        )
      );
      // setSmokeCart([...smokeCart]);
      // updateSmokeSubtotal(food[0].price);
      setSmokeCart([...smokeCart]);
      // const cartt = smokeCart.filter((item) => item.id === table);
      // console.log(cartt[0]);
      // console.log(smokeCart);
    }
  };

  const addToSmokeCartNest = (id, subcat, subPrice, index, table) => {
    const food = smokeFoods.filter((food) => food.id === id);
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items.filter(
      (food) => food.foodId === id && food.index === index
    );
    if (cart.length > 0) {
      increaseSmokeCartNestItem(cart[0].id, index, table);
    } else {
      var newFood = {
        id: Math.floor(Math.random() * 1000) + 1,
        foodId: food[0].id,
        index: index,
        name: food[0].name + "- " + subcat,
        price: parseFloat(subPrice),
        quantity: 1,
        nest: true,
        priceSum: parseFloat(subPrice),
      };
      cartNum[0].items = [...cartNum[0].items, newFood];

      setTables(
        tables.map((tab) =>
          tab.id === table
            ? {
                ...tab,
                active: true,
              }
            : tab
        )
      );
      setSmokeCart([...smokeCart]);
      // updateSmokeSubtotal(parseFloat(subPrice));
    }
  };



  const addToSmokeCartNestWithTopping = (id, subcat, subPrice, index, table, toppings) => {
    const food = smokeFoods.filter((food) => food.id === id);
    const cartNum = smokeCart.filter((item) => item.id === table);

    var add=0;
    var name='';
    var finalName=''
    toppings.map((t)=>{
      if(t>0){
          add=add+parseInt(toppingOptions[t].price)
          name=name+'-'+toppingOptions[t].name
        }
      })
    const cart = cartNum[0].items.filter(
      (food) => food.foodId === id && food.index === index
    );
    if (cart.length > 0) {
      increaseSmokeCartNestItem(cart[0].id, index, table);
    } else {
      if(name.length>0){
        finalName=food[0].name + "- " +subcat+name;
      }else{
        finalName=food[0].name + "- " +subcat
      }
      var newFood = {
        id: Math.floor(Math.random() * 1000) + 1,
        foodId: food[0].id+name,
        index: index,
        name: finalName,
        price: parseFloat(subPrice)+add,
        quantity: 1,
        nest: true,
        priceSum: parseFloat(subPrice)+add,
      };
      cartNum[0].items = [...cartNum[0].items, newFood];

      setTables(
        tables.map((tab) =>
          tab.id === table
            ? {
                ...tab,
                active: true,
              }
            : tab
        )
      );
      setSmokeCart([...smokeCart]);
      // updateSmokeSubtotal(parseFloat(subPrice));
    }
  };

  const removeSmokeCartitem = (id, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;
    cartNum[0].items = cart.filter((food) => food.id !== id);
    setSmokeCart([...smokeCart]);
    //setSmokeCart(smokeCart.filter((food) => food.id !== id));

    //const food = smokeCart.filter((food) => food.id === id);
    // reduceSmokeSubtotal(food[0].priceSum);
  };

  const removeSmokeCartNestitem = (id, table) => {
    const cartNum = smokeCart.filter((item) => item.id === table);
    const cart = cartNum[0].items;
    cartNum[0].items = cart.filter((food) => food.id !== id);
    setSmokeCart([...smokeCart]);
    //setSmokeCart(smokeCart.filter((food) => food.id !== id));
    //const food = smokeCart.filter((food) => food.id === id);
    // reduceSmokeSubtotal(food[0].priceSum);
  };

  //discount and charge data from web
  //discounts & charges //
  const [discountData, setDiscountData] = useState([]);
  const [chargeData, setChargeData] = useState([]);

  useEffect(() => {
    const fetchDiscountData = async () => {
      const url = apiDiscount;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const getDiscountData = async () => {
      const data = await fetchDiscountData();
      setDiscountData(data);
    };
    getDiscountData();
  }, [apiDiscount]);

  useEffect(() => {
    const fetchChargeData = async () => {
      const url = apiCharge;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const getChargeData = async () => {
      const data = await fetchChargeData();
      setChargeData(data);
    };
    getChargeData();
  }, [apiCharge]);

  return (
    <Router>
      <div className="App">
        <Route
          path="/"
          exact
          render={(props) => (
            <div>
              {" "}
              {smokeInfo.length > 0 ? (
                <Welcome smokeInfo={smokeInfo}></Welcome>
              ) : null}{" "}
            </div>
          )}
        />

        <Route
          path="/printNow"
          exact
          render={(props) => (
            <div id="printThis" className="printContent">
              Hello print
            </div>
          )}
        />
        {activePrint ? (
          <div id="printThis" className="printContent">
            <OnlineOrderPrint
              smokeInfo={smokeInfo[0]}
              selOrder={directPrintOrder}
              cartID={printCartId}
              foodName={printFoodName}
              qty={printQty}
              foodPrice={printPrice}
              condId={printCondId}
              condName={printCondName}
              condValue={printCondValue}
            ></OnlineOrderPrint>
          </div>
        ) : null}
        <div className="absolute top-0">
          <Navs
            cart={smokeCart}
            setSmokeCart={setSmokeCart}
            activeTable={activeTable}
            apiCustomer={apiCustomer}
            isSaveClient={isSaveClient}
            onlineOrderCount={onlineOrderCount}
          ></Navs>
        </div>
        <Route
          exact
          path="/epos/miyabi"
          render={(props) => (
            <div>
              <div className="grid grid-cols-9">
                <div className="col-span-5 mt-16">
                  <div className="flex items-center justify-between">
                    {/* <Navs
                      smokeClientID={smokeClientID}
                      clearClient={clearClient}
                      setSmokeClientID={setSmokeClientID}
                      setClientFname={setClientFname}
                      setClientLname={setClientLname}
                      setClientEmail={setClientEmail}
                      setClientPhone={setClientPhone}
                      setClientHouse={setClientHouse}
                      setClientStreet={setClientStreet}
                      setClientCity={setClientCity}
                      setClientPostcode={setClientPostcode}
                    ></Navs> */}
                    {/* {smokeInfo.length > 0 ? (
                      <h1 className="mx-4 text-lg font-semibold text-left border-b-4 border-yellow-500 whitespace-nowrap">
                        {smokeInfo[0].name}
                      </h1>
                    ) : null} */}
                  </div>
                  <div className="ml-1 rounded-lg foodbody">
                    <div className="flex items-start justify-start gap-2 border-t">
                      <div className="border-r">
                        <Category
                          cats={smokeCats}
                          onCategory={filterSmokeFood}
                          setActiveCategory={setActiveCategory}
                        />
                      </div>
                      <div className="max-w-max">
                        <Food
                          foods={FilterSmokeFoods}
                          onAdd={addToSmokeCart}
                          onToppingAdd={addToSmokeCartWithTopping}
                          addNest={addToSmokeCartNest}
                          addNestTopping={addToSmokeCartNestWithTopping}
                          activeCategory={activeCategory}
                          activeTable={activeTable}
                          toppings={toppings}
                          toppingOptions={toppingOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 border-l">
                  {smokeInfo.length > 0 ? (
                    <div className="flex items-start justify-between gap-1">
                      <>
                        <div className="h-screen border-r">
                          <p className="font-semibold text-gray-500">Carts</p>
                          {tables.map((table) => (
                            <TableButton
                              key={table.id}
                              setActiveTable={setActiveTable}
                              table={table}
                              activeTable={activeTable}
                            />
                          ))}
                        </div>

                        {tables.map((table) => (
                          <div
                            key={table.id}
                            className={`w-full  ${
                              table.id === activeTable ? `block` : `hidden`
                            }`}
                          >
                            <Cart
                              table={table}
                              activeTable={activeTable}
                              discountData={discountData}
                              chargeData={chargeData}
                              apiRegCustomer={apiRegCustomer}
                              apiPostOrder={apiPostOrder}
                              //smokeClientID={smokeClientID}
                              //setSmokeClientID={setSmokeClientID}
                              cartNum={smokeCart}
                              setCart={setSmokeCart}
                              addqnty={increaseSmokeCartItem}
                              redqnty={decreaseSmokeCartItem}
                              removeItem={removeSmokeCartitem}
                              nestRedqnty={decreaseSmokeCartNestItem}
                              nestAddqnty={increaseSmokeCartNestItem}
                              nestRemoveItem={removeSmokeCartNestitem}
                              smokeInfo={smokeInfo[0]}
                              // clientFname={clientFname}
                              // setClientFname={setClientFname}
                              // clientLname={clientLname}
                              // setClientLname={setClientLname}
                              // clientEmail={clientEmail}
                              // setClientEmail={setClientEmail}
                              // clientPhone={clientPhone}
                              // setClientPhone={setClientPhone}
                              // clientHouse={clientHouse}
                              // setClientHouse={setClientHouse}
                              // clientStreet={clientStreet}
                              // setClientStreet={setClientStreet}
                              // clientCity={clientCity}
                              // setClientCity={setClientCity}
                              // clientPostcode={clientPostcode}
                              // setClientPostcode={setClientPostcode}

                              activePrint={activePrint}
                              setisSaveClient={setisSaveClient}
                              silentPrint={silentPrint}
                            />
                          </div>
                        ))}
                      </>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        />

        <Route
          exact
          path="/epos/customerList"
          render={(props) => (
            <div>
              <CustomerList
                cart={smokeCart}
                setCart={setSmokeCart}
                activeTable={activeTable}
                // smokeClientID={smokeClientID}
                // setSmokeClientID={setSmokeClientID}
                // setClientFname={setClientFname}
                // setClientLname={setClientLname}
                // setClientEmail={setClientEmail}
                // setClientPhone={setClientPhone}
                // setClientHouse={setClientHouse}
                // setClientStreet={setClientStreet}
                // setClientCity={setClientCity}
                // setClientPostcode={setClientPostcode}

                apiCustomer={apiCustomer}
                apiRegCustomer={apiRegCustomer}
                setisSaveClient={setisSaveClient}
              ></CustomerList>
            </div>
          )}
        />

        <Route
          exact
          path="/epos/orderlist"
          render={(props) => (
            <div>
              {smokeInfo.length > 0 ? (
                <Orders
                  activePrint={activePrint}
                  smokeInfo={smokeInfo[0]}
                  apiOrders={apiOrders}
                  apiCustomer={apiCustomer}
                  apiRefund={apiRefund}
                  silentPrint={silentPrint}
                ></Orders>
              ) : null}
            </div>
          )}
        />

        <Route
          exact
          path="/epos/onlineOrderlist"
          render={(props) => (
            <div>
              {smokeInfo.length > 0 ? (
                <OnlineOrder
                  apiOnlineOrders={apiOnlineOrders}
                  apiOnlineOrderStatus={apiOnlineOrderStatus}
                  smokeInfo={smokeInfo[0]}
                  activePrint={activePrint}
                  silentPrint={silentPrint}
                  setOnlineOrderCount={setOnlineOrderCount}
                ></OnlineOrder>
              ) : null}
            </div>
          )}
        />

        <Route
          exact
          path="/epos/salesReport"
          render={() => (
            <div>
              {smokeInfo.length > 0 ? (
                <Report
                  apiCustomer={apiCustomer}
                  smokeInfo={smokeInfo[0]}
                  activePrint={activePrint}
                  silentPrint={silentPrint}
                ></Report>
              ) : null}
            </div>
          )}
        />

        <Route
          exact
          path="/epos/salesReport/online"
          render={() => (
            <div>
              {smokeInfo.length > 0 ? (
                <ReportOnline
                  smokeInfo={smokeInfo[0]}
                  activePrint={activePrint}
                  silentPrint={silentPrint}
                ></ReportOnline>
              ) : null}
            </div>
          )}
        />
      </div>
    </Router>
  );
}

export default App;
