import React from "react";
import Moment from "react-moment";
const orderPrint = ({
  smokeInfo,
  selOrder,
  cusData,
  cartID,
  foodName,
  qty,
  foodSum,
}) => {
  return (
    <div>
      <div className="px-0">
        <h1 className="text-4xl font-bold text-center">{smokeInfo["name"]}</h1>
        <p className="text-lg text-center">{smokeInfo["address1"]}</p>
        <p className="text-lg text-center">{smokeInfo["address2"]}</p>
        <p className="text-lg text-center">Tel: {smokeInfo["phone1"]}</p>

        {selOrder.orderType === "Eat-In" ? (
          <div className="flex justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left">
            <p className="text-xl"> Table no. {selOrder.tableNo + 1} </p>
            <p> Serving. {selOrder.serving} </p>
          </div>
        ) : null}

        <div className="w-full p-2 mb-1 text-xl font-bold text-left text-white bg-black">
          {selOrder.orderType}
        </div>

        <div className="flex justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-white bg-black">
          <p> {selOrder.payment} </p>
        </div>

        <div className="flex items-end justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-black border-black">
          <p className="text-2xl">[ #{selOrder.id} ]</p>

          <Moment format="H:mm, DD/MM/YYYY">{selOrder.created_at}</Moment>
        </div>

        {cusData.length > 0 ? (
          <div className="my-2">
            <p className="text-xl font-semibold text-left">Order Details:</p>

            <div className="flex justify-between w-full text-base">
              <p className="text-left">Name:</p>
              <p className="font-semibold text-right">
                {cusData[0].fname} {cusData[0].lname}
              </p>
            </div>

            <div className="flex justify-between w-full text-base">
              <p className="text-left">Phone:</p>
              <p className="font-semibold text-right">{cusData[0].phone}</p>
            </div>

            <div className="flex justify-between w-full text-base">
              <p className="text-left">Email:</p>
              <p className="font-semibold text-right">{cusData[0].email}</p>
            </div>

            <p className="mt-2 text-xl font-semibold text-left">Delivery:</p>
            <p className="text-base text-left">
              {cusData[0].house} {cusData[0].streer}
            </p>
            <p className="text-base text-left">
              {cusData[0].city}
              <span className="pl-1 font-semibold">{cusData[0].postcode}</span>
            </p>
          </div>
        ) : null}

        <p className="text-2xl font-semibold text-left border-b border-black">
          Items
        </p>
        <table className="w-full mx-auto table-auto">
          <tbody>
            {cartID.map((cart, index) => (
              <tr
                key={index}
                className="my-1 border-b border-black border-dotted"
              >
                <td className="text-lg font-semibold text-left">
                  {qty[index]}x{" "}
                  <span className="font-normal">{foodName[index]} </span>
                </td>

                <td className="flex items-center justify-end gap-2">
                  £{foodSum[index]}
                  <span>&#9744;</span>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-xl text-left">Sub Total</td>

              <td className="text-xl text-right">£{selOrder.subTotal}</td>
            </tr>

            {selOrder.appliedTotal ? (
              <tr>
                <td className="text-lg text-left">Discount</td>
                <td>
                  {selOrder.cashDisc > 0 ? (
                    <p className="text-lg text-right">-£{selOrder.cashDisc}</p>
                  ) : (
                    <p className="text-lg text-right">-{selOrder.percDisc}%</p>
                  )}
                </td>
              </tr>
            ) : null}

            {selOrder.charge > 0 ? (
              <tr>
                {selOrder.orderType === "Delivery" ? (
                  <td className="text-lg text-left">Delivery Charge</td>
                ) : (
                  <td className="text-lg text-left">Charge</td>
                )}
                <td className="text-lg text-right">£{selOrder.charge}</td>
              </tr>
            ) : null}

            <tr className="border-t border-black">
              <td className="text-3xl font-bold text-left">Total</td>
              <td className="text-3xl font-bold text-right">
                £{selOrder.total}
              </td>
            </tr>
            {selOrder.payment === "Cash" ? (
              <>
                <tr className="border-t border-black">
                  <td className="text-xl font-bold text-left">Paid</td>
                  <td className="text-xl font-bold text-right">
                    £{selOrder.paid}
                  </td>
                </tr>
                <tr>
                  <td className="text-xl font-bold text-left">Change</td>
                  <td className="text-xl font-bold text-right">
                    £{selOrder.changes}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>

        <div className="w-full mx-auto mt-2">
          {selOrder.note != null ? (
            <p className="text-base italic text-left">Note: {selOrder.note}</p>
          ) : null}
          <p className="mt-1 text-center border-t border-black">
            Thank you for ordering with us
          </p>
        </div>
      </div>
    </div>
  );
};

export default orderPrint;
