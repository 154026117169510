import React from "react";
import { useState, useEffect } from "react";
import Clock from "react-live-clock";
import { useHistory } from "react-router-dom";
import Star from "./Star";
import Refund from "./Refund";

const CustomerList = ({
  cart,
  setCart,
  activeTable,
  // setSmokeClientID,
  // setClientFname,
  // setClientLname,
  // setClientEmail,
  // setClientPhone,
  // setClientHouse,
  // setClientStreet,
  // setClientCity,
  // setClientPostcode,
  apiCustomer,
  apiRegCustomer,
  setisSaveClient,
}) => {
  const [userData, setUserData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [sort, setSort] = useState("def");

  // const [searchData, setSearchData] = useState([]);
  // const [openSearch, setOpenSearch] = useState(false);

  const [regFname, setRegFname] = useState("");
  const [regLname, setRegLname] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPhone, setRegPhone] = useState("");
  const [regHouse, setRegHouse] = useState("");
  const [regStreet, setRegStreet] = useState("");
  const [regCity, setRegCity] = useState("");
  const [regPostcode, setRegPostcode] = useState("");

  const history = useHistory();

  // const searchUser = (q) => {
  //   const data = userData.filter((user) => {
  //     if (
  //       user.fname.toLowerCase().includes(q.toLowerCase()) ||
  //       user.postcode.includes(q)
  //     ) {
  //       return user;
  //     } else {
  //       return null;
  //     }
  //   });
  //   setSearchData(data);
  // };
  // useEffect(() => {
  //   const getSmokeUsers = async () => {
  //     const smokeData = await fetchSmokeusers();
  //     setUserData(smokeData);
  //     setSearchData(smokeData);
  //   };

  //   getSmokeUsers();
  // }, []);

  useEffect(() => {
    const fetchSmokeusers = async () => {
      let url = apiCustomer;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const sorting = async () => {
      if (sort === "def") {
        const users = await fetchSmokeusers();
        users.sort((a, b) => {
          return b.fname - a.fname;
        });
        setUserData(users);
      }
      if (sort === "top") {
        const users = await fetchSmokeusers();
        users.sort((a, b) => {
          return b.orderCount - a.orderCount;
        });
        setUserData(users);
      }

      if (sort === "ref") {
        const users = await fetchSmokeusers();
        users.sort((a, b) => {
          return b.refundCount - a.refundCount;
        });
        setUserData(users);
      }
    };
    sorting();
  }, [apiCustomer, sort]);

  const selectUser = (id) => {
    const data = userData.filter((user) => user.id === id);

    const UpdatedCart = cart.map((item) =>
      item.id === activeTable
        ? {
            ...item,
            clientID: data[0].id,
            clientFname: data[0].fname,
            clientLname: data[0].lname,
            clientEmail: data[0].email,
            clientPhone: data[0].phone,
            clientHouse: data[0].house,
            clientStreet: data[0].street,
            clientCity: data[0].city,
            clientPostcode: data[0].postcode,
          }
        : item
    );
    setCart(UpdatedCart);

    // const data = userData.filter((user) => user.id === id);
    // setSmokeClientID(id);
    // setClientFname(data[0].fname);
    // setClientLname(data[0].lname);
    // setClientEmail(data[0].email);
    // setClientPhone(data[0].phone);
    // setClientHouse(data[0].house);
    // setClientStreet(data[0].street);
    // setClientCity(data[0].street);
    // setClientPostcode(data[0].postcode);
    history.push({
      pathname: `/epos/miyabi`,
    });
  };

  const saveClient = async (op) => {
    if (
      regFname !== "" &&
      regPhone !== "" &&
      regPhone.length === 11 &&
      regPostcode !== ""
    ) {
      let clientFname = regFname;
      let clientLname = regLname;
      let clientEmail = regEmail;
      let clientPhone = regPhone;
      let clientHouse = regHouse;
      let clientStreet = regStreet;
      let clientCity = regCity;
      let clientPostcode = regPostcode;

      let url = apiRegCustomer;

      await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          clientFname,
          clientLname,
          clientPhone,
          clientEmail,
          clientHouse,
          clientStreet,
          clientCity,
          clientPostcode,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          let func = async () => {
            const fetchSmokeusers = async () => {
              let url = apiCustomer;
              const res = await fetch(url);
              const data = await res.json();
              return data;
            };

            const smokeData = await fetchSmokeusers();
            setUserData(smokeData);

            if (op === 1) {
              const data = cart.filter((item) => item.id === activeTable);

              data[0].clientID = res.client.id;
              data[0].clientFname = res.client.fname;
              if (res.client.lname != null) {
                data[0].clientLname = res.client.lname;
              }
              if (res.client.email != null) {
                data[0].clientEmail = res.client.email;
              }
              data[0].clientPhone = res.client.phone;
              if (res.client.house != null) {
                data[0].clientHouse = res.client.house;
              }
              if (res.client.street != null) {
                data[0].clientStreet = res.client.street;
              }
              if (res.client.city != null) {
                data[0].clientCity = res.client.city;
              }
              data[0].clientPostcode = res.client.postcode;

              setCart([...cart]);

              history.push({
                pathname: `/epos/miyabi`,
              });
              setisSaveClient(2);
            } else {
              setRegFname("");
              setRegLname("");
              setRegEmail("");
              setRegPhone("");
              setRegHouse("");
              setRegStreet("");
              setRegCity("");
              setRegPostcode("");
              setisSaveClient(1);
            }
          };
          func();
        });
    } else {
      var msg = {};
      if (regPostcode === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Postcode is required",
        };
        setErrors([msg]);
      }
      if (regPhone === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Customer Phone number is required",
        };
        setErrors([msg]);
      }
      if (regPhone.length !== 11) {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Phone number must be 11 digits",
        };
        setErrors([msg]);
      }

      if (regFname === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Customer First name is required",
        };
        setErrors([msg]);
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-8 divide-x divide-gray-200">
        <div className="col-span-5 mt-16">
          <div>
            <div className="p-8 ml-1 bg-white rounded-lg ">
              <div className="flex items-center justify-between">
                <h4 className="text-2xl font-semibold">Register Customer</h4>
                {/* <button
                  onClick={() => setOpenSearch(true)}
                  className="p-2 text-white bg-blue-400 border-b-4 border-blue-700 rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-7 h-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button> */}
              </div>

              <h4 className="mb-2 text-xl font-semibold text-left border-b">
                Personal Details
              </h4>
              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex flex-col justify-start">
                  <label className="text-left text-red-500">*First Name</label>
                  <input
                    type="text"
                    value={regFname}
                    onChange={(e) => setRegFname(e.target.value)}
                    id="clientFname"
                    placeholder="First name"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>

                <div className="flex flex-col justify-start">
                  <label className="text-left">Last Name</label>
                  <input
                    type="text"
                    value={regLname}
                    onChange={(e) => setRegLname(e.target.value)}
                    id="clientLname"
                    placeholder="Last name"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>
              </div>

              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex flex-col justify-start">
                  <label className="text-left text-red-500">
                    *Land/Mobile Number
                  </label>
                  <input
                    type="number"
                    value={regPhone}
                    onChange={(e) => setRegPhone(e.target.value)}
                    id="clientPhone"
                    placeholder="07xxxxxxxxx"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>

                <div className="flex flex-col justify-start">
                  <label className="text-left">Email</label>
                  <input
                    type="email"
                    value={regEmail}
                    onChange={(e) => setRegEmail(e.target.value)}
                    id="clientEmail"
                    placeholder="customer@email.com"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>
              </div>

              <h4 className="mb-2 text-xl font-semibold text-left border-b">
                Address Details
              </h4>

              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex flex-col justify-start">
                  <label className="text-left">House</label>
                  <input
                    type="text"
                    value={regHouse}
                    onChange={(e) => setRegHouse(e.target.value)}
                    id="clientHouse"
                    placeholder="House"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>

                <div className="flex flex-col justify-start">
                  <label className="text-left">Street</label>
                  <input
                    type="email"
                    value={regStreet}
                    onChange={(e) => setRegStreet(e.target.value)}
                    id="clientStreet"
                    placeholder="Street"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>
              </div>

              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex flex-col justify-start">
                  <label className="text-left">City</label>
                  <input
                    type="text"
                    value={regCity}
                    onChange={(e) => setRegCity(e.target.value)}
                    id="clientCity"
                    placeholder="City"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>

                <div className="flex flex-col justify-start">
                  <label className="text-left text-red-500">*Post Code</label>
                  <input
                    type="email"
                    value={regPostcode}
                    onChange={(e) => setRegPostcode(e.target.value)}
                    id="clientZip"
                    placeholder="Post code"
                    className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                  />
                </div>
              </div>
              {errors.map((error) => (
                <p key={error.id} className="text-left text-red-400">
                  {error.msg}
                </p>
              ))}
              <div className="flex items-center justify-between">
                <button
                  onClick={() => saveClient(1)}
                  className="px-4 py-3 font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-lg"
                >
                  Save & Select
                </button>

                <button
                  onClick={() => saveClient(0)}
                  className="px-4 py-3 font-bold text-white bg-purple-400 border-b-4 border-purple-700 rounded-lg"
                >
                  Save Only
                </button>
              </div>
            </div>

            <div className="p-5 mt-1 ml-1 bg-white rounded-md ">
              <h4 className="flex items-center text-base font-semibold text-left text-yellow-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="ml-1">Important</span>
              </h4>
              <p className="mt-1 text-left">
                Customer First name, Land/Mobile Number and postcode is required
              </p>
              <p className="mt-1 text-left">
                Select any customer from the right to order for that customer
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="w-full h-screen p-1">
            <div className="w-full h-full px-2 bg-white rounded-md">
              <div className="flex justify-between border-b items-top">
                <h1 className="mb-1 text-xl font-bold text-left">
                  Customer List
                </h1>
                <Clock
                  format={"hh:mm:ssA"}
                  ticking={true}
                  timezone={"GB"}
                  className="text-base font-semibold"
                />
              </div>
              <div className="flex items-center justify-between p-1">
                <button
                  onClick={() => {
                    setSort("def");
                  }}
                  className={`${
                    sort === "def"
                      ? `bg-blue-200 text-blue-500 border-b-2 border`
                      : `bg-blue-500 text-white border-b-4`
                  } px-4 py-3 font-bold border-blue-700 rounded-md`}
                >
                  Default
                </button>
                <button
                  onClick={() => {
                    setSort("top");
                  }}
                  className={`${
                    sort === "top"
                      ? `bg-yellow-200 text-yellow-500 border-b-2 border`
                      : `bg-yellow-400 text-white border-b-4`
                  } px-8 py-3 font-bold border-yellow-700 rounded-md`}
                >
                  Top
                </button>
                <button
                  onClick={() => {
                    setSort("ref");
                  }}
                  className={`${
                    sort === "ref"
                      ? `bg-red-200 text-red-500 border-b-2 border`
                      : `bg-red-500 text-white border-b-4`
                  } px-4 py-3 font-bold border-red-700 rounded-md`}
                >
                  Refund
                </button>
              </div>
              <div className="p-1 userData">
                {userData.map((user) => (
                  <div
                    key={user.id}
                    className={` w-full p-3 my-2 ${
                      user.refundCount >= 5 ? `bg-red-100` : `bg-gray-100`
                    } rounded-md`}
                  >
                    <div className="flex justify-between items-top">
                      <div>
                        <Star orderCount={user.orderCount} />
                        <p className="text-sm font-semibold text-left">
                          Orders: {user.orderCount}
                        </p>
                        <h4 className="text-xl font-semibold text-left">
                          {user.fname} {user.lname}
                        </h4>
                        <div className="d-flex">
                          <p className="text-base text-left">{user.phone}</p>
                        </div>
                        <p className="text-sm text-left">{user.email}</p>
                        <p className="text-sm font-semibold text-left">
                          Accepted: {user.orderCount - user.refundCount}
                        </p>
                      </div>
                      <div>
                        <Refund refundCount={user.refundCount} />
                        <p className="text-sm font-semibold text-right">
                          Refund:{user.refundCount}
                        </p>
                        <p className="text-sm text-right">
                          {user.house} {user.street}
                        </p>
                        <p className="text-sm text-right">
                          {user.city}
                          <span className="font-medium"> {user.postcode}</span>
                        </p>
                        <button
                          onClick={() => selectUser(user.id)}
                          className={`float-right px-4 py-2 font-bold text-white ${
                            user.refundCount >= 5
                              ? `bg-red-400 border-red-700`
                              : `bg-blue-400 border-blue-700`
                          } border-b-4  rounded-md`}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {openSearch ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="m-5 search-wrapper">
            <div className="flex items-center justify-between">
              <input
                type="text"
                onChange={(e) => searchUser(e.target.value)}
                placeholder="Search Customer"
                className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg w-96 focus:border-yellow-400"
              />

              <button
                onClick={() => setOpenSearch(false)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {searchData.length > 0 ? (
              <div className="w-full p-4 bg-white rounded-lg shadow-xl searchContainer">
                {searchData.map((user) => (
                  <div
                    key={user.id}
                    className="w-full p-3 my-2 bg-gray-100 rounded-md"
                  >
                    <div className="flex justify-between items-top">
                      <div>
                        <Star orderCount={user.orderCount} />
                        <p className="text-sm font-semibold text-left">
                          Orders: {user.orderCount}
                        </p>
                        <h4 className="text-xl font-semibold text-left">
                          {user.fname} {user.lname}
                        </h4>
                        <div className="d-flex">
                          <p className="text-base text-left">{user.phone}</p>
                        </div>
                        <p className="text-sm text-left">{user.email}</p>
                      </div>
                      <div>
                        <Refund refundCount={user.refundCount} />
                        <p className="text-sm font-semibold text-right">
                          Refund:{user.refundCount}
                        </p>
                        <p className="text-sm text-right">
                          {user.house} {user.street}
                        </p>

                        <p className="text-sm text-right">
                          {user.city}
                          <span className="font-medium"> {user.postcode}</span>
                        </p>

                        <button
                          onClick={() => selectUser(user.id)}
                          className="float-right px-4 py-2 font-bold text-white bg-blue-400 border-b-4 border-blue-700 rounded-md"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full p-4 bg-white rounded-lg shadow-xl searchContainer">
                <p>No User Found</p>
              </div>
            )}
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default CustomerList;
