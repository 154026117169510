import React from "react";

const TableButton = ({ table, setActiveTable, activeTable }) => {
  return (
    <div>
      <button
        onClick={() => {
          setActiveTable(table.id);
        }}
        className={`relative w-14 h-14 min-h-full  text-lg font-bold transform  border-b-4 rounded-lg mb-1 transition-all duration-150 ease-in-out ${
          table.active
            ? ` border-green-500`
            : ` ${table.id > 1 ? `border-brownDark` : `border-OrangeDark`} `
        }    ${
          activeTable === table.id
            ? `ring-2 ring-blue-400 scale-100  ${
                table.id > 1
                  ? ` bg-yellow-200 text-black`
                  : `bg-OrangeDark text-white`
              }`
            : `scale-90   ${
                table.id > 1 ? ` bg-brown text-black` : `bg-Orange text-white`
              }`
        }
      
        `}
      >
        <span
          className={`absolute top-1 right-1 w-3 h-3 rounded-full 
        ${table.orderType === "Takeaway" ? `bg-blue-500` : ``}
        ${table.orderType === "Eat-In" ? `bg-yellow-500` : ``}
        ${table.orderType === "Delivery" ? `bg-red-500` : ``}
        `}
        ></span>

        {table.id > 1 ? (
          <span>{table.id - 1}</span>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 mx-auto"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default TableButton;
