import React, { useEffect } from "react";

import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import CartPrint from "../components/CartPrint";
import Clock from "react-live-clock";
import { useSwipeable } from "react-swipeable";
const Cart = ({
  table,
  activeTable,
  discountData,
  chargeData,
  apiRegCustomer,
  apiPostOrder,
  cartNum,
  setCart,
  addqnty,
  redqnty,
  removeItem,
  nestAddqnty,
  nestRedqnty,
  nestRemoveItem,
  smokeInfo,
  //smokeClientID,
  //setSmokeClientID,
  // clientFname,
  // setClientFname,
  // clientLname,
  // setClientLname,
  // clientEmail,
  // setClientEmail,
  // clientPhone,
  // setClientPhone,
  // clientHouse,
  // setClientHouse,
  // clientStreet,
  // setClientStreet,
  // clientCity,
  // setClientCity,
  // clientPostcode,
  // setClientPostcode,
  activePrint,
  setisSaveClient,
  silentPrint,
}) => {
  const [clientFname, setClientFname] = useState("");
  const [clientLname, setClientLname] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientHouse, setClientHouse] = useState("");
  const [clientStreet, setClientStreet] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientPostcode, setClientPostcode] = useState("");
  const [smokeClientID, setSmokeClientID] = useState(-1);

  const [cartData, setCartData] = useState([]);
  const [subTotal, setSubtotal] = useState();
  const [orderType, setOrderType] = useState("Takeaway");
  const [total, setTotal] = useState(subTotal);
  const [openCashDiscount, setopenCashDiscount] = useState(false);
  const [openPercDiscount, setopenPercDiscount] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [openCharge, setOpenCharge] = useState(false);
  const [openNote, setOpenNote] = useState(false);

  const [cashDisc, setCashDisc] = useState("");
  const [percDisc, setPercDisc] = useState("");
  const [appliedTotal, setAppliedTotal] = useState(false);

  const [charge, setCharge] = useState("");
  const [note, setNote] = useState("");
  const [payment, setpayment] = useState(null);
  const [percDiscTotal, setPercDiscTotal] = useState("");
  const [orderID, setOrderID] = useState(-1);
  const [errors, setErrors] = useState([]);

  const [cashOpen, setCashOpen] = useState(false);
  const [cashValue, setCashValue] = useState("");

  useEffect(() => {
    if (activeTable != null && table.id === activeTable) {
      const data = cartNum.filter((item) => item.id === activeTable);
      const cart = data[0].items;
      setCartData(cart);
      setSmokeClientID(data[0].clientID);
      setClientFname(data[0].clientFname);
      setClientLname(data[0].clientLname);
      setClientEmail(data[0].clientEmail);
      setClientPhone(data[0].clientPhone);
      setClientHouse(data[0].clientHouse);
      setClientStreet(data[0].clientStreet);
      setClientCity(data[0].clientCity);
      setClientPostcode(data[0].clientPostcode);
      setSubtotal(
        Object.values(cart).reduce((r, { priceSum }) => r + priceSum, 0)
      );
    }
  }, [activeTable, cartNum, table.id]);

  // useEffect(() => {
  //   if (table != null && table.id === activeTable) {
  //     setpayment(table.payment);
  //     setCharge(table.charge);
  //   }
  // }, [table, activeTable]);

  // useEffect(() => {
  //   if (table != null && table.id === activeTable) {
  //     if (table.discType === 0) {
  //       setPercDisc(table.discount);
  //       var percTotal = parseFloat(
  //         subTotal * (parseFloat(table.discount) / 100)
  //       ).toFixed(2);
  //       setPercDiscTotal(percTotal);
  //     } else if (table.discType === 1) {
  //       setCashDisc(table.discount);
  //     }
  //   }
  // }, [activeTable, subTotal, table]);

  useEffect(() => {
    if (payment === "Cash") {
      setCashOpen(true);
    }
    if (payment === "Card") {
      setCashOpen(false);
      setCashValue("");
    }
    if (payment === "Free") {
      setCashOpen(false);
      setCashValue("");
    }
  }, [payment]);

  useEffect(() => {
    const applyDicount = () => {
      if (discountData.length > 0) {
        if (
          (discountData[0].apply === 0 || discountData[0].apply === 3) &&
          subTotal > discountData[0].cond &&
          orderType === "Delivery"
        ) {
          let val = discountData[0].value;
          if (val.includes("%")) {
            val = val.slice(0, -1);
            setPercDisc(val);
            let percTotal = parseFloat(
              subTotal * (parseFloat(val) / 100)
            ).toFixed(2);
            setPercDiscTotal(percTotal);
          } else {
            setCashDisc(val);
            applySmokeCashDiscount();
          }
        } else if (
          (discountData[0].apply === 1 || discountData[0].apply === 3) &&
          subTotal > discountData[0].cond &&
          orderType === "Takeaway"
        ) {
          let val = discountData[0].value;
          if (val.includes("%")) {
            val = val.slice(0, -1);
            setPercDisc(val);
            let percTotal = parseFloat(
              subTotal * (parseFloat(val) / 100)
            ).toFixed(2);
            setPercDiscTotal(percTotal);
            setAppliedTotal(true);
          } else {
            setCashDisc(val);
            applySmokeCashDiscount();
            setAppliedTotal(true);
          }
        } else {
          setCashDisc("");
          setPercDisc("");
          setAppliedTotal(false);
          setPercDiscTotal(0);
        }
      }
    };
    applyDicount();
  }, [discountData, orderType, subTotal]);

  useEffect(() => {
    const applyCharge = () => {
      if (chargeData.length > 0) {
        if (orderType === "Delivery" && subTotal <= chargeData[0].cond) {
          let val = chargeData[0].value;
          if (val.includes("%")) {
            val = val.slice(0, -1);
            let charge = parseFloat(subTotal * (parseFloat(val) / 100)).toFixed(
              2
            );
            setCharge(charge);
          } else {
            setCharge(val);
          }
        } else {
          setCharge("");
        }
      }
    };
    applyCharge();
  }, [chargeData, discountData, orderType, subTotal]);
  //discounts & charges //

  useEffect(() => {
    const clearFree = () => {
      if (payment === "Free") {
        setCashDisc("");
        setPercDisc("");
        setAppliedTotal(false);
        setPercDiscTotal("");
        setTotal(0);
      } else {
        setTotal(subTotal);
      }
    };
    clearFree();
  }, [payment, subTotal]);

  useEffect(() => {
    const updateTotal = () => {
      if (!appliedTotal && charge === "") {
        if (payment === "Free") {
          setTotal(0);
        } else {
          setTotal(parseFloat(subTotal).toFixed(2));
        }
      } else {
        if (parseFloat(cashDisc) > 0) {
          let t = 0;
          if (parseFloat(charge) > 0) {
            t = parseFloat(subTotal).toFixed(2) + parseFloat(charge) - cashDisc;
          } else {
            t = parseFloat(subTotal).toFixed(2) - cashDisc;
          }

          setTotal(t);
        }
        if (parseFloat(percDiscTotal) > 0) {
          let t = 0;
          if (parseFloat(charge) > 0) {
            t =
              parseFloat(subTotal).toFixed(2) +
              parseFloat(charge) -
              parseFloat(percDiscTotal).toFixed(2);
          } else {
            t = parseFloat(subTotal - percDiscTotal).toFixed(2);
          }

          setTotal(t);
        }
        if (parseFloat(charge) > 0) {
          let t = 0;
          if (parseFloat(cashDisc) > 0) {
            t = parseFloat(subTotal) + parseFloat(charge) - cashDisc;
          } else if (percDiscTotal > 0) {
            t =
              parseFloat(subTotal) +
              parseFloat(charge) -
              parseFloat(percDiscTotal).toFixed(2);
          } else {
            t = parseFloat(subTotal) + parseFloat(charge);
          }
          setTotal(t);
        }
      }
    };
    updateTotal();
  }, [appliedTotal, subTotal, cashDisc, percDiscTotal, charge, payment]);

  //charge//
  const chargeInput = (value) => {
    setCharge(charge + value);
  };

  const chargeInputDel = () => {
    setCharge(charge.slice(0, -1));
  };

  const chargeInputClear = () => {
    setCharge("");
  };

  const applySmokeCharge = () => {
    // data=parseFloat(data)
    setOpenCharge(false);
  };

  const removeSmokeCharge = () => {
    setCharge("");
  };
  //charge//

  //cash discount//
  const cashInput = (value) => {
    setCashDisc(cashDisc + value);
  };

  const cashInputDel = () => {
    setCashDisc(cashDisc.slice(0, -1));
  };

  const cashInputClear = () => {
    setCashDisc("");
  };

  const applySmokeCashDiscount = () => {
    setAppliedTotal(true);
    setopenCashDiscount(false);
  };

  const removeSmokeCashDiscount = () => {
    setCashDisc("");
    setAppliedTotal(false);
  };
  //cash discount//

  //Perc discount//
  const percInput = (value) => {
    setPercDisc(percDisc + value);
  };

  const percInputDel = () => {
    setPercDisc(percDisc.slice(0, -1));
  };

  const percInputClear = () => {
    setPercDisc("");
  };

  const applySmokePercDiscount = () => {
    setAppliedTotal(true);
    setopenPercDiscount(false);
    var percTotal = parseFloat(subTotal * (parseFloat(percDisc) / 100)).toFixed(
      2
    );
    setPercDiscTotal(percTotal);
  };

  const removeSmokePercDiscount = () => {
    setPercDisc("");
    setAppliedTotal(false);
    setPercDiscTotal(0);
  };
  //Perc discount//

  const saveClient = async () => {
    if (
      clientFname !== "" &&
      clientPhone !== "" &&
      clientPhone.length === 11 &&
      clientPostcode !== ""
    ) {
      let url = apiRegCustomer;

      await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          clientFname,
          clientLname,
          clientPhone,
          clientEmail,
          clientHouse,
          clientStreet,
          clientCity,
          clientPostcode,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          setSmokeClientID(res.client.id);
          const data = cartNum.filter((item) => item.id === activeTable);

          data[0].clientID = res.client.id;
          data[0].clientFname = res.client.fname;
          if (res.client.lname != null) {
            data[0].clientLname = res.client.lname;
          }
          if (res.client.email != null) {
            data[0].clientEmail = res.client.email;
          }
          data[0].clientPhone = res.client.phone;
          if (res.client.house != null) {
            data[0].clientHouse = res.client.house;
          }
          if (res.client.street != null) {
            data[0].clientStreet = res.client.street;
          }
          if (res.client.city != null) {
            data[0].clientCity = res.client.city;
          }
          data[0].clientPostcode = res.client.postcode;

          setCart([...cartNum]);
          setOpenDelivery(false);
          setisSaveClient(3);
        });
    } else {
      var msg = {};
      if (clientPostcode === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Postcode is required",
        };
        setErrors([msg]);
      }
      if (clientPhone === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Customer Phone number is required",
        };
        setErrors([msg]);
      }
      if (clientPhone.length !== 11) {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Phone number must be 11 digits",
        };
        setErrors([msg]);
      }

      if (clientFname === "") {
        msg = {
          id: Math.floor(Math.random() * 1000) + 1,
          msg: "Customer First name is required",
        };
        setErrors([msg]);
      }
    }
  };

  const resetCart = () => {
    const data = cartNum.filter((item) => item.id === activeTable);
    data[0].items = [];
    setCart([...cartNum]);

    table.active = false;
    table.serving = 1;
    table.orderType = "";
    setTotal(0.0);
    setSubtotal(0.0);
    setCashDisc("");
    setPercDisc("");
    setAppliedTotal(false);
    setOrderType("Takeaway");
    setpayment(null);
    setPercDiscTotal(0);
    setOrderID(-1);
    setSmokeClientID(-1);
    setClientFname("");
    setClientLname("");
    setClientPhone("");
    setClientEmail("");
    setClientHouse("");
    setClientStreet("");
    setClientCity("");
    setClientPostcode("");
  };

  const orderPlace = async () => {
    let url = apiPostOrder;
    let tableNo = table.id - 1;
    let serving = table.serving;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        cartData,
        appliedTotal,
        percDiscTotal,
        cashDisc,
        percDisc,
        charge,
        subTotal,
        total,
        payment,
        cashValue,
        orderType,
        tableNo,
        serving,
        smokeClientID,
        note,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setOrderID(res);
      })
      .then((res) => {
        silentPrint();
      })
      .then((res) => {
        window.onafterprint = function () {
          resetCart();
        };
      });
  };

  // const handlePrint = () => {
  //   var divContents = document.getElementById("printThis").innerHTML;

  //   var a = window.open("", "", "height=500, width=302");
  //   a.document.write("<html>");
  //   a.document.write(
  //     '<head><link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">'
  //   );
  //   a.document.write(
  //     "<style> html, body {width: 80mm; height:100%;position:absolute;}</style></head>"
  //   );
  //   a.document.write("<body >");
  //   a.document.write(divContents);
  //   a.document.write("</body></html>");
  //   a.document.close();
  //   a.print();
  //   a.window.close();
  // };

  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: false,
    delta: 10,

    onSwipedRight: (eventData) => {
      if (eventData.absX > 200) {
        setCartData([]);
        setTotal(0);
        setSubtotal(0);
        const data = cartNum.filter((item) => item.id === activeTable);
        data[0].items = [];
        setCart([...cartNum]);
        table.active = false;
        table.serving = 1;
        table.orderType = "";
      }
    },
  });

  //cash Inputs
  const cashAmountInput = (value) => {
    setCashValue(cashValue + value);
  };

  const cashAmountDel = () => {
    setCashValue(cashValue.slice(0, -1));
  };

  const cashAmountClear = () => {
    setCashValue("");
  };
  //cash Inputs
  const [openServe, setOpenServe] = useState(false);

  const [serve, setServe] = useState("");

  const serveInput = (value) => {
    setServe(serve + value);
  };

  const serveInputDel = () => {
    setServe(serve.slice(0, -1));
  };

  const serveInputClear = () => {
    setServe("");
  };

  const applyServe = () => {
    table.serving = serve;
    setOpenServe(false);
  };

  return (
    <div>
      {!activePrint ? (
        <div id="printThis" className="printContent">
          <CartPrint
            smokeClientID={smokeClientID}
            table={table}
            clientFname={clientFname}
            clientLname={clientLname}
            clientEmail={clientEmail}
            clientPhone={clientPhone}
            clientHouse={clientHouse}
            clientStreet={clientStreet}
            clientCity={clientCity}
            clientPostcode={clientPostcode}
            cartData={cartData}
            cashDisc={cashDisc}
            appliedTotal={appliedTotal}
            percDiscTotal={percDiscTotal}
            percDisc={percDisc}
            subTotal={subTotal}
            total={total}
            smokeInfo={smokeInfo}
            orderType={orderType}
            payment={payment}
            cashValue={cashValue}
            orderId={orderID}
            charge={charge}
            note={note}
          />
        </div>
      ) : null}
      <div className="relative w-full h-screen p-1 pt-1 bg-white">
        <div
          className="flex justify-between mb-1 border-b items-top"
          {...handlers}
        >
          <div>
            <h1 className="text-lg font-bold text-left">
              Cart{table.id > 1 ? <>[{table.id - 1}]</> : null} - {orderType}
              {orderType === "Eat-In" ? <>[{table.serving}]</> : null} |{" "}
              {payment}
            </h1>
            <h1 className="text-xs font-semibold text-left">
              Ordering for -{" "}
              {smokeClientID > 0
                ? `${clientFname} - ${clientPostcode}`
                : "Random Customer"}
            </h1>
          </div>

          <div>
            <Clock
              format={"hh:mm:ssA"}
              ticking={true}
              timezone={"GB"}
              className="text-base font-semibold text-right"
            />
          </div>
        </div>

        <div className="cart-item-wrapper">
          {cartData.map((cart) => (
            <div
              key={cart.id}
              className={`grid items-center grid-cols-7 gap-2 py-0.5 border-b border-gray-200`}
            >
              <div className="col-span-3">
                <p className="text-sm font-semibold leading-none text-left">
                  {cart.quantity}x {cart.name}
                </p>
              </div>

              <div className="flex items-center col-span-2 gap-4 text-center">
                <button
                  onClick={() => {
                    cart.nest
                      ? nestAddqnty(cart.id, cart.index, activeTable)
                      : addqnty(cart.id, activeTable);
                  }}
                  className="bg-green-400 border-b-4 border-green-600 rounded-lg hover:scale-90 hover:bg-green-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 m-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    cart.nest
                      ? nestRedqnty(cart.id, cart.index, activeTable)
                      : redqnty(cart.id, activeTable);
                  }}
                  className="text-white bg-red-500 border-b-4 border-red-700 rounded-lg hover:scale-90 hover:bg-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 m-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-center justify-end col-span-2 text-center">
                <p className="pr-2 text-base font-semibold text-right">
                  £{parseFloat(cart.priceSum).toFixed(2)}
                </p>

                <button
                  onClick={() => {
                    cart.nest
                      ? nestRemoveItem(cart.id, activeTable)
                      : removeItem(cart.id, activeTable);
                  }}
                  className="text-white transform scale-100 bg-red-500 border-b-4 border-red-700 rounded-lg hover:scale-90 hover:bg-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 m-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
        {cartData.length > 0 ? (
          <div>
            <div className="flex justify-between border-t">
              <h1 className="text-base font-semibold">Subtotal</h1>
              <h1 className="text-lg font-semibold">
                £{parseFloat(subTotal).toFixed(2)}
              </h1>
            </div>

            <div>
              {percDiscTotal > 0 || cashDisc > 0 ? (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        percDiscTotal > 0
                          ? removeSmokePercDiscount()
                          : removeSmokeCashDiscount();
                      }}
                      className={`px-4 py-0.5 font-bold text-white text-base border-b-4 rounded-md ${
                        percDiscTotal > 0
                          ? `bg-purple-400 border-purple-700`
                          : `bg-red-400 border-red-700`
                      }`}
                    >
                      Discount
                    </button>
                  </div>
                  {percDiscTotal > 0 ? (
                    <h4 className="text-lg font-bold text-purple-500">
                      {percDisc}%
                    </h4>
                  ) : null}
                  {cashDisc > 0 ? (
                    <h4 className="text-lg font-bold text-red-500">
                      £{parseFloat(cashDisc).toFixed(2)}
                    </h4>
                  ) : null}
                </div>
              ) : null}

              {charge > 0 ? (
                <div className="flex items-center justify-between mt-1">
                  <div className="flex items-center">
                    <button
                      onClick={() => removeSmokeCharge()}
                      className="px-4 py-0.5 text-base font-bold text-white bg-green-400 border-b-4 border-green-700 rounded-md"
                    >
                      charge
                    </button>
                  </div>

                  <h1 className="text-lg font-bold text-green-400">
                    £{parseFloat(charge).toFixed(2)}
                  </h1>
                </div>
              ) : null}

              {payment === "Free" ? (
                <div className="flex justify-start">
                  <button
                    onClick={() => {
                      setpayment(null);
                    }}
                    className={`px-4 py-0.5 font-bold text-white text-base border-b-4 rounded-md bg-red-400 border-red-700 `}
                  >
                    Free
                  </button>
                </div>
              ) : null}
            </div>

            <div className="flex justify-between">
              <h1 className="text-lg font-bold">Total</h1>
              <h1 className="text-xl font-bold text-green-400">
                £{parseFloat(total).toFixed(2)}
              </h1>
            </div>

            {cashValue > 0 ? (
              <>
                <div className="flex justify-between border-t">
                  <h1 className="text-base font-semibold text-blue-500">
                    Paid
                  </h1>
                  <h1 className="text-xl font-bold text-blue-500">
                    £{parseFloat(cashValue).toFixed(2)}
                  </h1>
                </div>

                <div className="flex justify-between ">
                  <h1 className="text-base font-semibold text-red-500">
                    Change
                  </h1>
                  <h1 className="text-xl font-bold text-red-500">
                    £
                    {parseFloat(
                      parseFloat(cashValue).toFixed(2) -
                        parseFloat(total).toFixed(2)
                    ).toFixed(2)}
                  </h1>
                </div>
              </>
            ) : null}

            {cashOpen}

            <div className="absolute right-0 w-full px-1 bottom-1">
              <div className="flex items-center justify-between gap-2">
                <div
                  onClick={() => {
                    setOrderType("Takeaway");
                    table.orderType = "Takeaway";
                    table.serving = 1;
                  }}
                  className={
                    orderType === "Takeaway"
                      ? "w-full px-4 py-2.5 text-blue-700 font-semibold bg-blue-300 border-blue-700 border-2 rounded-lg"
                      : "w-full px-4 py-2.5 text-blue-600 font-semibold bg-blue-200 border-b-4 border-blue-400 rounded-lg"
                  }
                >
                  <span>Takeaway</span>
                </div>

                {orderType === "Eat-In" ? (
                  <div
                    onClick={() => {
                      setOpenServe(true);
                    }}
                    className="w-full px-4 py-2.5 text-white font-semibold bg-green-400 border-b-4 border-green-600 rounded-lg text-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mx-auto"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setOrderType("Eat-In");
                      table.orderType = "Eat-In";
                      setOpenServe(true);
                    }}
                    className={
                      orderType === "Eat-In"
                        ? "w-full px-4 py-2.5 text-yellow-700 font-semibold bg-yellow-300 border-yellow-700 border-2 rounded-lg"
                        : "w-full px-4 py-2.5 text-yellow-600 font-semibold bg-yellow-200 border-b-4 border-yellow-400 rounded-lg"
                    }
                  >
                    <span>Eat-In</span>
                  </div>
                )}
                <div
                  value="Delivery"
                  onClick={() => {
                    setOrderType("Delivery");
                    table.orderType = "Delivery";
                    table.serving = 1;
                    setOpenDelivery(true);
                  }}
                  className={
                    orderType === "Delivery"
                      ? "w-full px-4 py-2.5 text-red-700 font-semibold bg-red-300 border-red-700 border-2 rounded-lg"
                      : "w-full px-4 py-2.5 text-red-600 font-semibold bg-red-200 border-b-4 border-red-400 rounded-lg"
                  }
                >
                  <span>Delivery</span>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-2 my-1">
                {cashDisc > 0 ? (
                  <button className="px-2 py-1.5 text-2xl font-bold text-gray-400 bg-gray-300 border-b-4 border-gray-400 rounded-lg shadow-lg">
                    -%
                  </button>
                ) : (
                  <button
                    onClick={() => setopenPercDiscount(!openPercDiscount)}
                    className="px-2 py-1.5 text-2xl font-bold text-white bg-purple-400 border-b-4 border-purple-600 rounded-lg shadow-lg hover:bg-purple-500"
                  >
                    -%
                  </button>
                )}
                {percDiscTotal > 0 ? (
                  <button className="px-2 py-1.5 text-2xl font-bold text-gray-400 bg-gray-300 border-b-4 border-gray-400 rounded-lg shadow-lg">
                    -£
                  </button>
                ) : (
                  <button
                    onClick={() => setopenCashDiscount(!openCashDiscount)}
                    className="px-2 py-1.5 text-2xl font-bold text-white bg-red-400 border-b-4 border-red-600 rounded-lg shadow-lg hover:bg-red-500"
                  >
                    -£
                  </button>
                )}

                <button
                  onClick={() => setOpenCharge(!openCharge)}
                  className="px-2 py-1.5 text-2xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
                >
                  +£
                </button>
              </div>

              <RadioGroup
                value={payment}
                onChange={setpayment}
                className="flex items-center justify-between gap-2 my-1"
              >
                {/* <RadioGroup.Option
                  value="Cash"
                  className={({ checked }) =>
                    checked
                      ? "w-full px-4 py-3 text-green-800 font-semibold bg-green-400 border-green-700 border-2  rounded-lg"
                      : "w-full px-4 py-3 text-green-700 font-semibold bg-green-300 border-b-4 border-green-400 rounded-lg"
                  }
                >
                  <span>Cash</span>
                </RadioGroup.Option> */}
                <button
                  onClick={() => {
                    setpayment("Cash");
                    setCashOpen(true);
                  }}
                  className={`w-full px-4 py-2.5  font-semibold  rounded-lg 
                ${
                  payment === "Cash"
                    ? "text-green-800 bg-green-400 border-green-700 border-2"
                    : " text-green-700 bg-green-300 border-b-4 border-green-400"
                }`}
                >
                  Cash
                </button>
                <RadioGroup.Option
                  value="Card"
                  className={({ checked }) =>
                    checked
                      ? "w-full px-4 py-2.5 text-yellow-600 font-semibold bg-yellow-300  border-yellow-700 border-2 rounded-lg"
                      : "w-full px-4 py-2.5 text-yellow-700 font-semibold bg-yellow-200 border-b-4 border-yellow-400 rounded-lg"
                  }
                >
                  <span>Card</span>
                </RadioGroup.Option>

                <RadioGroup.Option
                  value="Free"
                  className={({ checked }) =>
                    checked
                      ? "w-full px-4 py-2.5 text-red-600 font-semibold bg-red-300  border-red-700 border-2 rounded-lg"
                      : "w-full px-4 py-2.5 text-red-700 font-semibold bg-red-200 border-b-4 border-red-400 rounded-lg"
                  }
                >
                  <span>Free</span>
                </RadioGroup.Option>
              </RadioGroup>
              <div className="flex items-center justify-between gap-2 my-1">
                <button
                  onClick={() => {
                    setOpenNote(true);
                  }}
                  className="w-1/4 px-2 py-1.5 text-2xl font-semibold text-white bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path
                      fillRule="evenodd"
                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {!activePrint ? (
                  <button
                    onClick={() => {
                      silentPrint();
                    }}
                    className="w-1/4 px-2 py-1.5 text-2xl font-semibold text-white bg-blue-400 border-b-4 border-blue-600 rounded-lg shadow-lg hover:bg-blue-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                  </button>
                ) : (
                  <button className="w-1/4 px-2 py-1.5 text-2xl font-semibold text-gray-600 bg-gray-300 border-b-4 border-gray-500 rounded-lg shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                  </button>
                )}
                {orderType === "Delivery" && smokeClientID === -1 ? (
                  <button className="w-1/2 px-2 py-1.5 pt-0 pb-0 text-xl font-semibold text-gray-600 bg-gray-300 border-b-4 border-gray-500 rounded-lg shadow-lg">
                    Checkout
                    <p className="pb-1 text-xs text-gray-500">
                      Add customer Info{" "}
                    </p>
                  </button>
                ) : (
                  <>
                    {!activePrint ? (
                      <>
                        {payment != null ? (
                          <>
                            {payment === "Cash" ? (
                              <>
                                {cashValue - total >= 0 ? (
                                  <button
                                    onClick={orderPlace}
                                    className="w-1/2 px-2 py-1.5 text-2xl font-semibold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
                                  >
                                    Checkout
                                  </button>
                                ) : (
                                  <button className="w-1/2 px-2 py-1.5 pt-0 pb-0 text-xl font-semibold text-gray-600 bg-gray-300 border-b-4 border-gray-500 rounded-lg shadow-lg">
                                    Checkout
                                    <p className="pb-1 text-xs text-gray-500">
                                      Please Update Cash Amount
                                    </p>
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                onClick={orderPlace}
                                className="w-1/2 px-2 py-1.5 text-2xl font-semibold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
                              >
                                Checkout
                              </button>
                            )}
                          </>
                        ) : (
                          <button className="w-1/2 px-2 py-1.5 pt-0 pb-0 text-xl font-semibold text-gray-600 bg-gray-300 border-b-4 border-gray-500 rounded-lg shadow-lg">
                            Checkout
                            <p className="pb-1 text-xs text-gray-500">
                              Please Select Payment
                            </p>
                          </button>
                        )}
                      </>
                    ) : (
                      <button className="w-1/2 px-2 py-1.5 text-xl font-semibold text-gray-600 bg-gray-300 border-b-4 border-gray-500 rounded-lg shadow-lg">
                        Checkout
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {openCharge ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white backdrop-filter backdrop-blur-sm bg-opacity-30">
          <div className="bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
            <h4 className="mt-2 text-2xl font-semibold text-white">
              Total: £{parseFloat(total).toFixed(2)}
            </h4>
            <div className="flex items-center justify-between m-3">
              <div className="flex items-center justify-start">
                <p className="text-2xl font-bold text-white">+</p>
                <input
                  type="text"
                  value={charge}
                  className="p-2 m-2 text-2xl font-bold rounded-lg shadow-lg w-80 h-14"
                  readOnly
                />
              </div>
              <button
                onClick={() => setOpenCharge(!openCharge)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 m-3">
              <button
                value="1"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 bg-gray1200 hover:bg-gray-400"
              >
                1
              </button>
              <button
                value="2"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                2
              </button>
              <button
                value="3"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                3
              </button>

              <button
                value="4"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                4
              </button>
              <button
                value="5"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                5
              </button>
              <button
                value="6"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                6
              </button>

              <button
                value="7"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                7
              </button>
              <button
                value="8"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                8
              </button>
              <button
                value="9"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                9
              </button>

              <button
                value="."
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                .
              </button>
              <button
                value="0"
                onClick={(e) => chargeInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                0
              </button>
              <button
                onClick={() => chargeInputDel()}
                className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
              >
                del
              </button>
            </div>
            <div className="grid grid-cols-2 m-3">
              <button
                onClick={() => chargeInputClear()}
                className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
              >
                Clear
              </button>
              <button
                onClick={() => {
                  applySmokeCharge();
                  if (payment === "Free") {
                    setpayment("Free");
                  }
                }}
                className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {openCashDiscount ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white backdrop-filter backdrop-blur-sm bg-opacity-30">
          <div className="bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
            <h4 className="mt-2 text-2xl font-semibold text-white">
              Total: £{parseFloat(total).toFixed(2)}
            </h4>
            <div className="flex items-center justify-between m-3">
              <div className="flex items-center justify-start">
                <p className="text-2xl font-bold text-white">-</p>
                <input
                  type="text"
                  value={cashDisc}
                  className="p-2 m-2 text-2xl font-bold rounded-lg shadow-lg w-80 h-14"
                  readOnly
                />
              </div>
              <button
                onClick={() => setopenCashDiscount(!openCashDiscount)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 m-3">
              <button
                value="1"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 bg-gray1200 hover:bg-gray-400"
              >
                1
              </button>
              <button
                value="2"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                2
              </button>
              <button
                value="3"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                3
              </button>

              <button
                value="4"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                4
              </button>
              <button
                value="5"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                5
              </button>
              <button
                value="6"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                6
              </button>

              <button
                value="7"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                7
              </button>
              <button
                value="8"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                8
              </button>
              <button
                value="9"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                9
              </button>

              <button
                value="."
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                .
              </button>
              <button
                value="0"
                onClick={(e) => cashInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                0
              </button>
              <button
                onClick={() => cashInputDel()}
                className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
              >
                del
              </button>
            </div>
            <div className="grid grid-cols-2 m-3">
              <button
                onClick={() => cashInputClear()}
                className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
              >
                Clear
              </button>
              <button
                onClick={() => {
                  applySmokeCashDiscount();
                  if (payment === "Free") {
                    setpayment(null);
                  }
                }}
                className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {openPercDiscount ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white backdrop-filter backdrop-blur-sm bg-opacity-30">
          <div className="bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
            <h4 className="mt-2 text-2xl font-semibold text-white">
              Total: £{parseFloat(total).toFixed(2)}
            </h4>
            <div className="flex items-center justify-between m-3">
              <div className="flex items-center justify-start">
                <p className="text-2xl font-bold text-white">%</p>
                <input
                  type="text"
                  value={percDisc}
                  className="p-2 m-2 text-2xl font-bold rounded-lg shadow-lg w-80 h-14"
                  readOnly
                />
              </div>

              <button
                onClick={() => setopenPercDiscount(!openPercDiscount)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 m-3">
              <button
                value="1"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                1
              </button>
              <button
                value="2"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                2
              </button>
              <button
                value="3"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                3
              </button>

              <button
                value="4"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                4
              </button>
              <button
                value="5"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                5
              </button>
              <button
                value="6"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                6
              </button>

              <button
                value="7"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                7
              </button>
              <button
                value="8"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                8
              </button>
              <button
                value="9"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                9
              </button>

              <button
                value="."
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                .
              </button>
              <button
                value="0"
                onClick={(e) => percInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                0
              </button>
              <button
                onClick={() => percInputDel()}
                className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
              >
                del
              </button>
            </div>
            <div className="grid grid-cols-2 m-3">
              <button
                onClick={() => percInputClear()}
                className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
              >
                Clear
              </button>
              <button
                onClick={() => applySmokePercDiscount()}
                className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {openDelivery ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="p-10 bg-white rounded-lg shadow-2xl">
            <div className="flex items-center justify-between">
              <h4 className="text-2xl font-semibold">Delivery Details</h4>
              <button
                onClick={() => setOpenDelivery(false)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <h4 className="mb-2 text-xl font-semibold text-left border-b">
              Personal Details
            </h4>
            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex flex-col justify-start">
                <label className="text-left text-red-500">*First Name</label>
                <input
                  type="text"
                  value={clientFname}
                  onChange={(e) => setClientFname(e.target.value)}
                  id="clientFname"
                  placeholder="First name"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>

              <div className="flex flex-col justify-start">
                <label className="text-left">Last Name</label>
                <input
                  type="text"
                  value={clientLname}
                  onChange={(e) => setClientLname(e.target.value)}
                  id="clientLname"
                  placeholder="Last name"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex flex-col justify-start">
                <label className="text-left text-red-500">
                  *Land/Mobile Number
                </label>
                <input
                  type="number"
                  value={clientPhone}
                  onChange={(e) => setClientPhone(e.target.value)}
                  id="clientPhone"
                  placeholder="07xxxxxxxxx"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>

              <div className="flex flex-col justify-start">
                <label className="text-left">Email</label>
                <input
                  type="email"
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  id="clientEmail"
                  placeholder="customer@email.com"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>
            </div>

            <h4 className="mb-2 text-xl font-semibold text-left border-b">
              Address Details
            </h4>

            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex flex-col justify-start">
                <label className="text-left">House</label>
                <input
                  type="text"
                  value={clientHouse}
                  onChange={(e) => setClientHouse(e.target.value)}
                  id="clientHouse"
                  placeholder="House"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>

              <div className="flex flex-col justify-start">
                <label className="text-left">Street</label>
                <input
                  type="email"
                  value={clientStreet}
                  onChange={(e) => setClientStreet(e.target.value)}
                  id="clientStreet"
                  placeholder="Street"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex flex-col justify-start">
                <label className="text-left">City</label>
                <input
                  type="text"
                  value={clientCity}
                  onChange={(e) => setClientCity(e.target.value)}
                  id="clientCity"
                  placeholder="City"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>

              <div className="flex flex-col justify-start">
                <label className="text-left text-red-500">*Post Code</label>
                <input
                  type="email"
                  value={clientPostcode}
                  onChange={(e) => setClientPostcode(e.target.value)}
                  id="clientZip"
                  placeholder="Post code"
                  className="h-12 p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
                />
              </div>
            </div>
            {errors.map((error) => (
              <p key={error.id} className="text-left text-red-400">
                {error.msg}
              </p>
            ))}
            <div className="flex items-center justify-start">
              {smokeClientID === -1 ? (
                <button
                  onClick={saveClient}
                  className="px-4 py-3 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
                >
                  {" "}
                  Save Record
                </button>
              ) : (
                <div>
                  <button className="px-4 py-3 m-1 text-xl font-bold text-white bg-green-300 border-b-4 border-green-400 rounded-lg shadow-lg hover:bg-green-500">
                    {" "}
                    Save Record
                  </button>
                  <p className="text-left">Record already saved</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {openNote ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="p-10 bg-white rounded-lg shadow-2xl">
            <div className="flex items-center justify-between">
              <h4 className="text-2xl font-semibold">Add note</h4>
              <button
                onClick={() => setOpenNote(false)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="mt-5 w-96 h-96">
              <textarea
                rows="6"
                onChange={(e) => setNote(e.target.value)}
                value={note}
                className="w-full p-2 mb-2 text-xl border-2 border-gray-400 rounded-lg focus:border-yellow-400"
              ></textarea>

              <button
                onClick={() => {
                  setOpenNote(false);
                }}
                className="px-8 py-3 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {cashOpen ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white backdrop-filter backdrop-blur-sm bg-opacity-30">
          <div className="bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
            <div className="flex items-center justify-center divide-x-2 divide-solid">
              <h4 className="px-4 mt-2 text-base font-semibold text-white">
                Total:{" "}
                <span
                  className={` ${cashValue > 0 ? "text-xl " : "text-3xl "}`}
                >
                  {" "}
                  £{parseFloat(total).toFixed(2)}
                </span>
              </h4>
              {cashValue > 0 ? (
                <h4 className="px-4 mt-2 text-base font-semibold text-white">
                  Change:{" "}
                  <span className="text-3xl">
                    {" "}
                    £
                    {parseFloat(
                      parseFloat(cashValue) - parseFloat(total)
                    ).toFixed(2)}
                  </span>
                </h4>
              ) : null}
            </div>

            <div className="flex items-center justify-between m-3">
              <div className="flex items-center justify-start">
                <p className="text-2xl font-bold text-white">Cash</p>
                <input
                  type="text"
                  value={cashValue}
                  className="p-2 m-2 text-2xl font-bold rounded-lg shadow-lg w-80 h-14"
                  readOnly
                />
              </div>

              <button
                onClick={() => {
                  setCashOpen(false);
                }}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 m-3">
              <button
                value="1"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                1
              </button>
              <button
                value="2"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                2
              </button>
              <button
                value="3"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                3
              </button>

              <button
                value="4"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                4
              </button>
              <button
                value="5"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                5
              </button>
              <button
                value="6"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                6
              </button>

              <button
                value="7"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                7
              </button>
              <button
                value="8"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                8
              </button>
              <button
                value="9"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                9
              </button>

              <button
                value="."
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                .
              </button>
              <button
                value="0"
                onClick={(e) => cashAmountInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                0
              </button>
              <button
                onClick={() => cashAmountDel()}
                className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
              >
                del
              </button>
            </div>
            <div className="grid grid-cols-2 m-3">
              <button
                onClick={() => cashAmountClear()}
                className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
              >
                Clear
              </button>
              <button
                onClick={() => setCashOpen(false)}
                className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {openServe ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white backdrop-filter backdrop-blur-sm bg-opacity-30">
          <div className="bg-gray-800 rounded-lg shadow-lg cashDiscount-content backdrop-filter backdrop-blur-sm bg-opacity-80">
            <div className="flex items-center justify-between m-3">
              <div className="flex items-center justify-start">
                <p className="text-2xl font-bold text-white">Serving </p>
                <input
                  type="text"
                  value={serve}
                  className="p-2 m-2 text-2xl font-bold rounded-lg shadow-lg w-80 h-14"
                  readOnly
                />
              </div>
              <button
                onClick={() => setOpenServe(!openServe)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 m-3">
              <button
                value="1"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 bg-gray1200 hover:bg-gray-400"
              >
                1
              </button>
              <button
                value="2"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                2
              </button>
              <button
                value="3"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                3
              </button>

              <button
                value="4"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                4
              </button>
              <button
                value="5"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                5
              </button>
              <button
                value="6"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                6
              </button>

              <button
                value="7"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                7
              </button>
              <button
                value="8"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                8
              </button>
              <button
                value="9"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                9
              </button>

              <button
                value="."
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                .
              </button>
              <button
                value="0"
                onClick={(e) => serveInput(e.target.value)}
                className="p-4 m-1 text-xl font-bold border-b-4 border-gray-400 rounded-lg shadow-lg bg-gray-50 hover:bg-gray-400"
              >
                0
              </button>
              <button
                onClick={() => serveInputDel()}
                className="p-4 m-1 text-xl font-bold bg-yellow-400 border-b-4 border-yellow-600 rounded-lg shadow-lg hover:bg-yellow-500"
              >
                del
              </button>
            </div>
            <div className="grid grid-cols-2 m-3">
              <button
                onClick={() => serveInputClear()}
                className="p-4 m-1 text-xl font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg hover:bg-red-600"
              >
                Clear
              </button>
              <button
                onClick={() => {
                  applyServe();
                }}
                className="p-4 m-1 text-xl font-bold text-white bg-green-400 border-b-4 border-green-600 rounded-lg shadow-lg hover:bg-green-500"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Cart;
