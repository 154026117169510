import React from "react";
import Clock from "react-live-clock";
const CartPrint = ({
  table,
  smokeClientID,
  clientFname,
  clientLname,
  clientEmail,
  clientPhone,
  clientHouse,
  clientStreet,
  clientCity,
  clientPostcode,
  cartData,
  appliedTotal,
  percDiscTotal,
  cashDisc,
  percDisc,
  subTotal,
  total,
  smokeInfo,
  orderType,
  cashValue,
  payment,
  orderId,
  note,
  charge,
}) => {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  return (
    <div className="px-0">
      <h1 className="text-4xl font-bold text-center">{smokeInfo["name"]}</h1>
      <p className="text-lg text-center">{smokeInfo["address1"]}</p>
      <p className="text-lg text-center">{smokeInfo["address2"]}</p>
      <p className="text-lg text-center">Tel: {smokeInfo["phone1"]}</p>

      {orderType === "Eat-In" ? (
        <div className="flex justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left">
          <p className="text-xl"> Table no. {table.id - 1} </p>
          <p> Serving. {table.serving} </p>
        </div>
      ) : null}

      <div className="w-full p-2 mb-1 text-xl font-bold text-left text-white bg-black ">
        {orderType}
      </div>

      <div className="flex justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-white bg-black">
        <p> {payment} </p>
      </div>

      <div className="flex items-end justify-between w-full gap-2 p-2 mb-1 text-lg font-bold text-left text-black border-black">
        {orderId > 0 ? (
          <p className="text-left">
            Order ID: <br />{" "}
            <span className="text-lg font-bold">#{orderId}</span>
          </p>
        ) : (
          <p></p>
        )}

        <p className="text-right">
          <Clock format={"HH:mm"} ticking={false} timezone={"GB"} />, {date}
        </p>
      </div>

      {smokeClientID > 0 ? (
        <div className="my-2">
          <p className="text-xl font-semibold text-left">Order Details:</p>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Name:</p>
            <p className="font-semibold text-right">
              {clientFname} {clientLname}
            </p>
          </div>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Phone:</p>
            <p className="font-semibold text-right">{clientPhone}</p>
          </div>

          <div className="flex justify-between w-full text-base">
            <p className="text-left">Email:</p>
            <p className="font-semibold text-right">{clientEmail}</p>
          </div>

          <p className="mt-2 text-xl font-semibold text-left">Delivery:</p>
          <p className="text-base text-left">
            {clientHouse} {clientStreet}
          </p>
          <p className="text-base text-left">
            {clientCity}
            <span className="pl-1 font-semibold">{clientPostcode}</span>
          </p>
        </div>
      ) : null}

      <p className="text-2xl font-semibold text-left border-b border-black">
        Items
      </p>
      <table className="w-full mx-auto table-auto">
        <tbody>
          {cartData.map((cart) => (
            <tr
              key={cart.id}
              className="my-1 border-b border-black border-dotted"
            >
              <td className="text-lg font-semibold text-left">
              <span>&#9744; &nbsp;</span>
                {cart.quantity}x{" "}
                <span className="font-normal">{cart.name} </span>
              </td>

              <td className="flex items-center justify-end gap-2">
                £{parseFloat(cart.priceSum).toFixed(2)}
                
              </td>
            </tr>
          ))}
          <tr>
            <td className="text-xl text-left">Sub Total</td>
            <td className="text-xl text-right">
              £{parseFloat(subTotal).toFixed(2)}
            </td>
          </tr>
          {appliedTotal ? (
            <tr>
              <td className="text-lg text-left">Discount</td>
              <td>
                {percDiscTotal > 0 ? (
                  <p className="text-lg text-right">- {percDisc}%</p>
                ) : (
                  <p className="text-lg text-right">
                    - £{parseFloat(cashDisc).toFixed(2)}
                  </p>
                )}
              </td>
            </tr>
          ) : null}

          {charge !== "" ? (
            <tr>
              {orderType === "Delivery" ? (
                <td className="text-lg text-left">Delivery Charge</td>
              ) : (
                <td className="text-lg text-left">Charge</td>
              )}
              <td className="text-lg text-right">
                £{parseFloat(charge).toFixed(2)}
              </td>
            </tr>
          ) : null}
          <tr className="border-t border-black">
            <td className="text-3xl font-bold text-left">Total</td>
            <td className="text-3xl font-bold text-right">
              <p> £{parseFloat(total).toFixed(2)} </p>
            </td>
          </tr>

          {payment === "Cash" ? (
            <>
              <tr className="border-t border-black">
                <td className="text-xl font-bold text-left">Paid</td>
                <td className="text-xl font-bold text-right">
                  £{parseFloat(cashValue).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="text-xl font-bold text-left">Change</td>
                <td className="text-xl font-bold text-right">
                  £{parseFloat(cashValue - total).toFixed(2)}
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
      <div className="w-full mx-auto mt-2">
        <p className="text-base italic text-left">Note: {note}</p>
        <p className="mt-1 text-center border-t border-black">
          Thank you for ordering with us
        </p>
      </div>
    </div>
  );
};

export default CartPrint;
