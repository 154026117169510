import React, { useEffect, useState } from "react";
import Moment from "react-moment";

const ReportPrintOnline = ({
  smokeInfo,
  time,
  printTime,
  year,
  total,
  cashTotal,
  cardTotal,
  take,
  deli,
}) => {
  const [recordTime, setRecordTime] = useState(null);
  const [format, setFormat] = useState("DD/MM/YYYY");
  useEffect(() => {
    if (time === -2) {
      setRecordTime("Yesterday's");
      setFormat("DD/MM/YYYY");
    }
    if (time === -1) {
      setRecordTime("Today's");
      setFormat("DD/MM/YYYY");
    }
    if (time === 0) {
      setRecordTime("Week's");
      setFormat("DD MMMM,YYYY");
    }
    if (time > 0 && time <= 12) {
      setRecordTime("Month's");
      setFormat("MMMM,YYYY");
    }
    if (time > 0 && time <= 12) {
      setRecordTime("Month's");
      setFormat("MMMM,YYYY");
    }

    if (time > 12) {
      setRecordTime("Year's");
      setFormat("YYYY");
    }
  }, [time]);
  return (
    <div>
      <h1 className="text-4xl font-bold text-center">{smokeInfo["name"]}</h1>
      <p className="text-lg text-center">{smokeInfo["address1"]}</p>
      <p className="text-lg text-center">{smokeInfo["address2"]}</p>
      <p className="text-lg text-center">Tel: {smokeInfo["phone1"]}</p>

      <div className="w-full gap-2 p-2 mb-1 text-xl font-bold text-center text-white bg-black">
        <p>{recordTime} Report</p>
      </div>
      <p className="text-lg font-semibold text-center">
        {time > 12 ? (
          <Moment format={format}>{year}</Moment>
        ) : (
          <Moment format={format}>{printTime}</Moment>
        )}
      </p>
      <div className="mt-10 border-b border-black border-dashed">
        <div className="flex items-center justify-between gap-2 mb-4 ">
          <p className="text-2xl font-semibold text-left">Orders. </p>
          <p className="text-4xl font-bold">{take + deli}</p>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <p className="text-lg font-normal text-right">
            Collection. <span className="text-xl font-bold">{take}</span>
          </p>
          <p className="text-lg font-normal text-right">
            Delivery. <span className="text-xl font-bold">{deli}</span>
          </p>
        </div>
      </div>
      <div className="mt-10 border-b border-black border-dashed">
        <div className="flex items-center justify-between gap-2 mb-4">
          <p className="text-2xl font-semibold text-left">Total.</p>
          <p className="text-4xl font-bold">£{total}</p>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <p className="text-lg font-normal text-right">
            Cash. <span className="text-xl font-bold">£{cashTotal}</span>
          </p>
          <p className="text-lg font-normal text-right">
            Card. <span className="text-xl font-bold">£{cardTotal}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportPrintOnline;
