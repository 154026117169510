import React from "react";
import CatButton from "./ui/catButton";
import { useState } from "react";
const Category = ({ cats, onCategory, setActiveCategory }) => {
  const [active, setActive] = useState(-1);

  return (
    <div className="px-0 py-1">
      <div className="overflow-y-auto catList-wrapper">
        {cats.map((cat) => (
          <CatButton
            cat={cat}
            key={cat.id}
            active={active}
            setActive={setActive}
            onCategory={onCategory}
            setActiveCategory={setActiveCategory}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
